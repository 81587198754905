import find from 'lodash/find';
import first from 'lodash/first';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';

import { BOOT_ERRORS } from '../../selectors/boot';
import { REQUEST_STATUS } from '../../selectors/preFetchedData';

import createError from './createError';

export default async ({ chapterRef, courseAsPromise, preFetchedData }: any) => {
  const preFetchedDataRequest = preFetchedData.get('chapter');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return Promise.resolve(preFetchedDataRequest.get('data'));
    case REQUEST_STATUS.FAILED:
      return Promise.reject(
        createError({ message: preFetchedDataRequest.get('data') }),
      );
    default: {
      const course = await courseAsPromise;
      let currentChapter;
      if (isNil(chapterRef)) {
        currentChapter = first(course.chapters);
      } else {
        currentChapter = find(
          course.chapters,
          (chapter) =>
            chapter.id.toString() === chapterRef || chapter.slug === chapterRef,
        );
      }
      return isNil(currentChapter)
        ? Promise.reject(
            createError({ message: BOOT_ERRORS.CHAPTER_NOT_FOUND }),
          )
        : currentChapter;
    }
  }
};
