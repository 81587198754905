import ApiClient from '../../../helpers/ApiClient';
import { getMainAppApiClient } from '../../../helpers/getClients';

export default async ({
  chapterAsPromise,
  courseAsPromise,
  isPreview,
  skipLtiCheck,
}: any) => {
  if (isPreview || skipLtiCheck) {
    return false;
  }

  const course = await courseAsPromise;
  if (!course.lti_only) {
    return false;
  }

  const chapter = await chapterAsPromise;
  return getMainAppApiClient()
    .execute(
      ApiClient.endpoint.ltiIsActive({
        courseId: course.id,
        chapterId: chapter.id,
      }),
    )
    .toPromise()
    .then(() => false)
    .catch(() => true);
};
