import { Map as hashMap } from 'immutable';

import * as actions from '../actions';

export default (
  state = hashMap(),
  action: actions.Action = actions.emptyAction,
) => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      return state
        .set(
          'current',
          hashMap({
            pathname: action.pathname,
            query: hashMap(action.queryParams),
          }),
        )
        .set('language', action.language)
        .set('canonical', action.canonical);
    case actions.UPDATE_LOCATION:
      return state.set('before', state.get('current')).set(
        'current',
        hashMap({
          pathname: action.pathname,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type '{}'.
          query: hashMap(action.query),
        }),
      );
    default:
      return state;
  }
};
