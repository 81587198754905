import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import { datawarehouseApi } from '../../helpers/api/clients';
import { DataWarehouseSession } from '../../helpers/api/dataWarehouseSession';
import type { State } from '../../interfaces/State';
import unreachable from '../../interfaces/unreachable';
import * as actions from '../actions';
import { Action } from '../actions';
import { DatawarehouseSessionInfo } from '../reducers/datawarehouseSession';
import * as selectors from '../selectors';

function isNotFoundError(error: unknown): boolean {
  return (
    error != null &&
    typeof error === 'object' &&
    (('status' in error && error.status === 404) ||
      ('statusCode' in error && error.statusCode === 404))
  );
}

/**
 * Epic to check if we should create a datawarehouse session.
 */
export const epicCheckDatawarehouseSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
) =>
  action$.ofType(actions.EPIC_START_SESSION).switchMap(
    (): Rx.Observable<Action> => {
      const state: State = store.getState();
      const language = selectors.selectLanguage(state);
      const query = selectors.selectQuery(state).toJS();
      const course = selectors.selectCourse(state).toJS();
      const datawarehouseSessionState = selectors.selectDatawarehouseSession(
        state,
      );

      const courseIdFromQuery = parseInt(query.course_id, 10);
      const courseId = Number.isInteger(courseIdFromQuery)
        ? courseIdFromQuery
        : course.id;

      const isAlreadyLoadingForSameCourse =
        datawarehouseSessionState.status === 'loading' &&
        datawarehouseSessionState.courseId === courseId;

      if (isAlreadyLoadingForSameCourse) {
        return Rx.Observable.empty();
      }

      const canSkipDatawarehouseSessionStart =
        language !== 'sql' || selectors.selectIsUserNotLoggedIn(state);
      if (canSkipDatawarehouseSessionStart) {
        return Rx.Observable.of(
          actions.setDatawarehouseSession({
            courseId,
            session: null,
          }),
        );
      }

      return Rx.Observable.of(
        actions.epicStartDatawarehouseSession({ courseId }),
      );
    },
  );

export const epicSetLoading = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(actions.EPIC_START_DATAWAREHOUSE_SESSION)
    .switchMap((action) =>
      Rx.Observable.of(
        actions.setDatawarehouseSessionLoading({ courseId: action.courseId }),
      ),
    );

const pollDataWarehouseApi = ({
  courseId,
  sessionId,
}: {
  courseId: number;
  sessionId: string;
}): Rx.Observable<Action> => {
  return Rx.Observable.interval(1000)
    .switchMap(() => {
      return datawarehouseApi.getDatawarehouseSession({ sessionId });
    })
    .timeout(60000)
    .first(({ status }) => {
      switch (status) {
        case 'ready':
        case 'error':
        case 'expired':
          return true;
        case 'creating':
          return false;
        default:
          throw new Error(`Unknown session status: ${status}`);
      }
    })
    .map((session) => {
      switch (session.status) {
        case 'ready':
          return actions.setDatawarehouseSession({
            courseId,
            session: {
              sessionId,
              dbName: session.dbName,
              dbUser: session.dbUser,
              dbRole: session.dbRole,
              dbPassword: session.dbPassword,
            },
          });
        case 'error':
          return actions.setDatawarehouseSessionError({
            error: new Error('Failed to create data warehouse session'),
            courseId,
          });
        case 'expired':
          return actions.setDatawarehouseSessionError({
            error: new Error('Session has expired'),
            courseId,
          });
        case 'creating':
          // Should be unreachable
          return actions.noopAction;
        default:
          return unreachable(session);
      }
    })
    .catch((error) => {
      return Rx.Observable.of(
        actions.setDatawarehouseSessionError({
          error,
          courseId,
        }),
      );
    });
};

export const epicStartDatawarehouseSession = (
  action$: ActionsObservable<Action>,
) =>
  action$
    .ofType(actions.EPIC_START_DATAWAREHOUSE_SESSION)
    .switchMap(
      async (
        action,
      ): Promise<
        | { action: Action; type: 'action' }
        | {
            action: actions.EpicStartDatawarehouseSessionAction;
            session: DataWarehouseSession;
            type: 'session';
          }
      > => {
        try {
          const session = await datawarehouseApi.createDatawarehouseSession({
            courseId: action.courseId,
          });
          return { type: 'session' as const, session, action };
        } catch (error) {
          // Course doesn't have a snapshot
          if (isNotFoundError(error)) {
            return {
              type: 'action',
              action: actions.setDatawarehouseSession({
                courseId: action.courseId,
                session: null,
              }),
            };
          }
          // Failed to initialize
          return {
            type: 'action',
            action: actions.setDatawarehouseSessionError({
              error,
              courseId: action.courseId,
            }),
          };
        }
      },
    )
    .switchMap((createDatawarehouseSessionResult) => {
      if (createDatawarehouseSessionResult.type === 'action') {
        return Rx.Observable.of(createDatawarehouseSessionResult.action);
      }

      const { action, session } = createDatawarehouseSessionResult;
      const { status } = session;

      switch (status) {
        case 'ready':
          return Rx.Observable.of(
            actions.setDatawarehouseSession({
              courseId: action.courseId,
              session: {
                sessionId: session.id,
                dbName: session.dbName,
                dbUser: session.dbUser,
                dbRole: session.dbRole,
                dbPassword: session.dbPassword,
              },
            }),
          );
        case 'creating':
          return pollDataWarehouseApi({
            courseId: action.courseId,
            sessionId: session.id,
          });
        case 'error':
          return Rx.Observable.of(
            actions.setDatawarehouseSessionError({
              error: new Error('Failed to create data warehouse session'),
              courseId: action.courseId,
            }),
          );
        case 'expired':
          return Rx.Observable.of(
            actions.setDatawarehouseSessionError({
              error: new Error('Session has expired'),
              courseId: action.courseId,
            }),
          );
        default:
          throw new Error(`Unknown session status: ${status}`);
      }
    });

type SetDatawarehouseSessionActionWithSession = actions.SetDatawarehouseSessionAction & {
  session: DatawarehouseSessionInfo;
};

function isActionWithSession(
  action: actions.SetDatawarehouseSessionAction,
): action is SetDatawarehouseSessionActionWithSession {
  return action.session !== null;
}

export const epicPingDatawarehouseSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
) =>
  action$
    .ofType(actions.SET_DATAWAREHOUSE_SESSION)
    .filter(isActionWithSession)
    .switchMap((action) => {
      const datawarehouseSession = selectors.selectDatawarehouseSession(
        store.getState(),
      );
      if (datawarehouseSession.status === 'success') {
        return Rx.Observable.timer(0, 30000)
          .takeWhile(
            () =>
              selectors.selectDatawarehouseSession(store.getState()).status ===
              'success',
          )
          .mergeMap(() => {
            datawarehouseApi
              .keepDatawarehouseSessionAlive({
                sessionId: action.session.sessionId,
              })
              .catch(() => Rx.Observable.empty());
            return Rx.Observable.empty();
          });
      }
      return Rx.Observable.empty();
    });
