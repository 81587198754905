import languagesConfig, {
  getConsoleTitle,
  getTabTitle,
  // @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
} from '@datacamp/dc-languages-config';
import { t } from 'i18next';
import { fromJS, Map as hashMap } from 'immutable';
import attempt from 'lodash/attempt';
import endsWith from 'lodash/endsWith';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isError from 'lodash/isError';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';
import uniqueId from 'lodash/uniqueId';

const exercisesWithoutScript = [
  'ConsoleExercise',
  'TabConsoleExercise',
  'BulletConsoleExercise',
  'MultipleChoiceExercise',
];

export default (exercise: any) => {
  const language = exercise.get('language');
  const config = get(languagesConfig, language, {});
  const exType = exercise.get('type');
  let inputMarkdownTabs = {};
  if (exType === 'MarkdownExercise') {
    const sampleCode = attempt(() => JSON.parse(exercise.get('sample_code')));
    if (!isError(sampleCode)) {
      inputMarkdownTabs = mapValues(sampleCode, (value, key) => ({
        title: key,
        props: {
          code: value,
          active: endsWith(key, '.Rmd'),
          resetCode: value,
          uniqueId: uniqueId('code-editor-'),
        },
      }));
    }
  }

  const subExercises = exercise.get('subexercises');
  let currentXp = exercise.get('xp', 0);
  if (subExercises && !subExercises.isEmpty()) {
    currentXp = subExercises.reduce(
      (acc: any, subEx = hashMap()) => acc + subEx.get('xp'),
      0,
    );
  }

  const isLegacyExercise = !startsWith(exercise.get('sample_code'), '[');

  /**
   * This creates the initially opened file in the code editor for "legacy" exercises.
   */
  function createLegacyExerciseEditorTabs() {
    const tabTitle = getTabTitle(
      config.editorPrefixTitle,
      language,
      exercise.get('type'),
    );
    const scriptTabKey = `files/${tabTitle}`;

    const shouldHaveTab =
      !includes(exercisesWithoutScript, exercise.get('type')) ||
      language === 'sql';
    let tabs = {};

    if (shouldHaveTab) {
      tabs = {
        [scriptTabKey]: {
          title: tabTitle,
          isSolution: false,
          props: {
            active: true,
            isClosable: false,
            code: null,
            extra: {},
          },
        },
      };
    }

    return tabs;
  }

  /**
   * Create initially opened tabs for exercises with multiple files based on
   * flags in the sample code.
   * @param {*} currentExercise
   */
  function createNewExerciseEditorTabs(currentExercise: any) {
    const sampleCode = JSON.parse(currentExercise.get('sample_code'));
    const openedFiles = filter(
      sampleCode,
      (file) => file.open && !file.isFolder,
    );
    return reduce(
      openedFiles,
      (tabs, file) => {
        const tabKey = ['files', file.name].join('/');
        // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
        // eslint-disable-next-line no-param-reassign
        tabs[tabKey] = {
          title: file.name,
          isSolution: false,
          props: {
            active: file.focus === true,
            isClosable: true,
            readOnly: file.locked === true,
            code: file.content,
            resetCode: file.content,
          },
        };
        return tabs;
      },
      {},
    );
  }

  return fromJS({
    editorTabs: isLegacyExercise
      ? createLegacyExerciseEditorTabs()
      : createNewExerciseEditorTabs(exercise),
    consoleTabs: {
      console: {
        title: getConsoleTitle(language),
        props: {
          active: true,
        },
        dimension: { cols: 400 },
      },
      slides: {
        title: t('TabSlides.header'),
        props: {
          active: false,
        },
      },
    },
    consoleSqlTabs: {
      query_result: {
        extraClass: '',
        title: 'query result',
        props: {
          active: true,
          isNotView: true,
          message: 'No query executed yet...',
        },
      },
    },
    consoleObjectViewTabs: {},
    graphicalTabs: {
      plot: {
        extraClass: 'animation--flash',
        title: 'Plots',
        props: {
          sources: [],
          currentIndex: 0,
        },
        dimension: {
          // TODO: HANDLE_RESIZE
          // Remove isRealSize property
          isRealSize: false,
          width: 1,
          height: 1,
        },
      },
      html: {
        extraClass: 'animation--flash',
        title: 'HTML Viewer',
        props: {
          sources: [],
          currentIndex: 0,
        },
      },
    },
    inputMarkdownTabs,
    outputMarkdownTabs: {},
    markdown: {
      titles: ['Knit PDF', 'Knit HTML'],
      activeTitle: 'Knit HTML',
    },
    feedbackMessages: [],
    isHintShown: false,
    lastSubmittedCode: null,
    lastRunCode: null,
    currentXp,
    ltiStatus: {},
    lastSubmitActiveEditorTab: null,
    usedAiFeatures: {
      aiIncorrectSubmissions: false,
      aiErrorExplanations: false,
    },
  });
};
