import Cookies from 'js-cookie';

import getBackToDashboardSurvey from '../../helpers/getBackToDashboardSurvey';
import type { TechnologyId } from '../../helpers/technology';
import type { State } from '../../interfaces/State';

import {
  selectChapterNumber,
  selectExerciseIndex,
  selectTechnologyId,
} from '.';

const backToDashboardModalCourse = (technologyId: TechnologyId) =>
  getBackToDashboardSurvey(technologyId) != null;

const wasSurveyShownInLast30Days = () => {
  const lastShownAt = Cookies.get('lx-dashboard-survey');
  const today = Date.now();
  const oneDay = 1000 * 60 * 60 * 24;
  const thirtyDaysAgo = today - 30 * oneDay;
  // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'string' a... Remove this comment to see the full error message
  return lastShownAt !== undefined && lastShownAt > thirtyDaysAgo;
};

export const shouldShowSurveyModal = (state: State) => {
  const courseTechnologyId = selectTechnologyId(state);
  const chapterNumber = selectChapterNumber(state);
  const exerciseNumber = selectExerciseIndex(state);

  return (
    backToDashboardModalCourse(courseTechnologyId) &&
    chapterNumber === 1 &&
    exerciseNumber < 7 &&
    !wasSurveyShownInLast30Days()
  );
};
