import ApiClient from '../../../helpers/ApiClient';
import fetchExternalExerciseIds, {
  Exercise,
} from '../../../helpers/fetchExternalExerciseIds';
import {
  getCampusApiClient,
  getTeachClient,
} from '../../../helpers/getClients';
import { PreFetchedDataState } from '../../reducers/preFetchedData';
import { BOOT_ERRORS, REQUEST_STATUS } from '../../selectors';

import createError from './createError';

export default async ({
  chapterAsPromise,
  courseAsPromise,
  exerciseProgrammingLanguageOverride,
  isPreview,
  language,
  preFetchedData,
}: {
  chapterAsPromise: Promise<any>;
  courseAsPromise: Promise<any>;
  exerciseProgrammingLanguageOverride: any;
  isPreview: boolean;
  language: string;
  preFetchedData: PreFetchedDataState;
}): Promise<Exercise[]> => {
  const preFetchedDataRequest = preFetchedData.get('exercises');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return Promise.resolve(preFetchedDataRequest.get('data') as Exercise[]);
    case REQUEST_STATUS.FAILED:
      return Promise.reject(
        createError({ message: preFetchedDataRequest.get('data') }),
      );
    default: {
      const client = isPreview ? getTeachClient() : getCampusApiClient();
      const course = await courseAsPromise;
      const chapter = await chapterAsPromise;

      return client
        .execute(
          ApiClient.endpoint.getExercises(course.id, chapter.id, {
            language,
          }),
        )
        .toPromise()
        .then(({ body: exercises }: { body: unknown[] }) =>
          exercises.map((exercise: any) => {
            const programmingLanguage =
              exerciseProgrammingLanguageOverride ||
              exercise.programming_language ||
              course.programming_language;
            return {
              ...exercise,
              language: programmingLanguage,
              randomNumber: Math.random(),
            };
          }),
        )
        .then((exercises: Exercise[]) =>
          fetchExternalExerciseIds(course, chapter.id, exercises),
        )
        .catch((error: any) =>
          Promise.reject(
            createError({
              message: BOOT_ERRORS.UNKNOWN,
              originalError: error,
            }),
          ),
        );
    }
  }
};
