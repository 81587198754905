import floor from 'lodash/floor';

import { isExamExercise } from '../../../helpers/examUtilities';

export const getHintOrSolution = (exercise: any, subExercise: any) => {
  const getData = (
    {
      hint,
      isHintShown,
      isSolutionShown,
      solution,
      xp,
    }: {
      hint: any;
      isHintShown: boolean;
      isSolutionShown: boolean;
      solution: string;
      xp: number;
    },
    isSubExCompleted?: boolean,
  ) => {
    let xpToLoose = floor((isHintShown ? 0.7 : 0.3) * xp);

    // edge case where a solution exists but there isn't hint
    if (!hint && solution) {
      isHintShown = true; // eslint-disable-line
      xpToLoose = xp;
    }

    // visible only if the user didn't get the hint (and there is one) or if it's time to show the solution
    // if it's a sub ex and it's completed, never show any helps
    let isVisible =
      (!isHintShown && Boolean(hint)) ||
      (!isSolutionShown && Boolean(solution));
    if (isSubExCompleted) {
      isVisible = false;
    }
    if (isExamExercise(exercise.get('type')) && isHintShown) {
      isVisible = false;
    }
    return {
      isVisible,
      isSolution: isHintShown,
      isSolutionShown,
      xp: xpToLoose,
    };
  };

  if (!subExercise.isEmpty()) {
    // dealing with a subexercise
    return getData(
      subExercise.toJS(),
      subExercise.getIn(['completed', 'completed']),
    );
  }
  return getData({
    isHintShown: exercise.getIn(['user', 'isHintShown'], false),
    isSolutionShown: exercise.getIn(['user', 'isSolutionShown'], false),
    xp: exercise.get('xp', 0),
    hint: exercise.get('hint', false),
    solution: exercise.get('solution', false),
  });
};
