import raven from 'raven-js';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import ApiClient from '../../../helpers/ApiClient';
import { getMainAppApiClient } from '../../../helpers/getClients';
import { technologyById } from '../../../helpers/technology';
import { trackFirstChapterCompleted } from '../../../helpers/trackProgressGtm';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

const mainAppClient = getMainAppApiClient();

const sendEventIfFirstCompletedChapter = (
  action: any,
  chapterId: string,
  course: any,
) => {
  const technology =
    technologyById[
      (course.get('technology_id') as unknown) as keyof typeof technologyById
    ];
  const isFree = !course.get('paid');

  return mainAppClient
    .execute(ApiClient.endpoint.getCompletedChapterCount(), action)
    .concatMapOnSucceedRequest((response: any) => {
      if (response.data.body.completed_chapter_count === 1) {
        trackFirstChapterCompleted(chapterId, technology, isFree);
      }
      return Rx.Observable.empty();
    })
    .catch((e: any) => {
      raven.captureException(e, { extra: { extraInfo: JSON.stringify(e) } });
      return Rx.Observable.empty();
    });
};

export const epicFirstChapterCompleted = (action$: any, store: any) =>
  action$.ofType(actions.EXERCISE_COMPLETED).concatMap((action: any) => {
    const state = store.getState();
    const chapterId = selectors.selectChapterId(state);
    const course = selectors.selectCourse(state);

    const progress = selectors.selectGlobalUserProgress(state);
    if (!progress.chapter?.completed && !progress.course?.completed) {
      return Rx.Observable.empty();
    }
    return sendEventIfFirstCompletedChapter(action, chapterId, course);
  });
