import { Images } from '../../helpers/ApiClient';
import { Action, BOOT_SUCCEEDED } from '../actions';

export type ImagesState = Images | null;

const defaultState: ImagesState = null;

export const images = (
  state: ImagesState = defaultState,
  action: Action,
): ImagesState => {
  switch (action.type) {
    case BOOT_SUCCEEDED:
      return action.entities.images;
    default:
      return state;
  }
};
