import { MultiplexerJsonRpcSessionManager } from '../../helpers/jsonRpc/multiplexerJsonRpcSessionManager';
import { State } from '../../interfaces/State';
import { BackendSessionJsonRpcState } from '../reducers/backendSessionJsonRpc';

export const selectBackendSessionJsonRpc = (
  state: State,
): BackendSessionJsonRpcState => state.get('backendSessionJsonRpc');

export const selectMuxSessionManager = (
  state: State,
): MultiplexerJsonRpcSessionManager | null => {
  const backendSessionJsonRpc = selectBackendSessionJsonRpc(state);
  if (backendSessionJsonRpc.status === 'loaded') {
    return backendSessionJsonRpc.muxSessionManager;
  }
  return null;
};
