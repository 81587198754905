import { createSelector } from 'reselect';

import config from '../../config';

export const BOOT_STATE = {
  NONE: 'NONE',
  BOOTING: 'BOOTING',
  PRE_BOOTED: 'PRE_BOOTED',
  BOOTED: 'BOOTED',
} as const;
export type BootState = typeof BOOT_STATE[keyof typeof BOOT_STATE];

export const BOOT_ERRORS = {
  COURSE_NOT_FOUND: 'COURSE_NOT_FOUND',
  CHAPTER_NOT_FOUND: 'CHAPTER_NOT_FOUND',
  LTI_NOT_ACTIVE: 'LTI_NOT_ACTIVE',
  UNKNOWN_ROUTE: 'UNKNOWN_ROUTE',
  UNKNOWN: 'UNKNOWN',
};

const selectState = (state: any) => state.get('boot');
export const selectIsApplicationBooted = createSelector(
  selectState,
  (state) => state.get('bootState') === BOOT_STATE.BOOTED,
);
export const selectIsApplicationPreBooted = createSelector(
  selectState,
  selectIsApplicationBooted,
  (state, isBooted) =>
    isBooted || state.get('bootState') === BOOT_STATE.PRE_BOOTED,
);
export const selectIsApplicationBootable = createSelector(
  selectState,
  selectIsApplicationPreBooted,
  selectIsApplicationBooted,
  (state, isPreBooted, isBooted) => {
    if (state.get('bootState') === BOOT_STATE.BOOTING) {
      return false;
    }
    if (isBooted) {
      return false;
    }
    if (isPreBooted && config.isServerSideRendering) {
      return false;
    }
    return true;
  },
);
