import ApiClient from '../../../helpers/ApiClient';
import {
  getCampusApiClient,
  getMainAppApiClient,
} from '../../../helpers/getClients';
import { USER_STATUS } from '../../selectors/user';

const defaultProgress = {
  courseProgress: null,
  chapterProgress: null,
  streakInfo: undefined,
};

export default async ({
  chapterAsPromise,
  courseAsPromise,
  isPreview,
  userAsPromise,
}: any) => {
  if (isPreview) {
    return defaultProgress;
  }

  const user = await userAsPromise;
  if (user.status === USER_STATUS.UNAUTHORIZED) {
    return defaultProgress;
  }

  const course = await courseAsPromise;
  const chapter = await chapterAsPromise;

  return Promise.all([
    getCampusApiClient()
      .execute(ApiClient.endpoint.getCourseProgress({ courseRef: course.id }))
      .toPromise(),
    getCampusApiClient()
      .execute(
        ApiClient.endpoint.getChapterProgress({
          courseRef: course.id,
          chapterRef: chapter.id,
        }),
      )
      .toPromise(),
    getMainAppApiClient()
      .execute(ApiClient.endpoint.getStreak())
      .toPromise()
      .catch(() => {
        // Don't crash the app if the call fails (just hide the XP counter)
        return { body: undefined };
      }),
  ])
    .then(
      ([
        { body: courseProgress },
        { body: chapterProgress },
        { body: streakInfo },
      ]) => ({
        courseProgress,
        chapterProgress,
        streakInfo,
      }),
    )
    .catch(() => defaultProgress);
};
