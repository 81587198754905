import { fromJS, Map as hashMap } from 'immutable';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import result from 'lodash/result';

import { activateTab } from '../../../helpers/tabs';
import * as actions from '../../actions';
import { Action } from '../../actions';

// @ts-expect-error ts-migrate(6133) FIXME: 'exercise' is declared but its value is never read... Remove this comment to see the full error message
export default (state: any, exercise: any, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_SQL_ERROR:
      return state
        .update(activateTab('query_result'))
        .update('query_result', (tab = hashMap()) =>
          tab.setIn(
            ['props', 'error'],
            fromJS({
              message: result(action, 'settings.output.payload'),
              show: true,
            }),
          ),
        );
    case actions.TOGGLE_SQL_ERROR:
      return state.setIn(
        ['query_result', 'props', 'error', 'show'],
        Boolean(action.show),
      );
    case actions.UPDATE_SQL_QUERY_MESSAGE:
      return state
        .update(activateTab('query_result'))
        .update('query_result', (tab = hashMap()) =>
          tab
            .set('title', action.settings.name)
            .setIn(['props', 'error', 'show'], false)
            .removeIn(['props', 'table'])
            .setIn(['props', 'message'], action.settings.message),
        );
    case actions.UPDATE_SQL_QUERY_RESULT:
      return state
        .update(activateTab('query_result'))
        .update('query_result', (tab = hashMap()) =>
          tab
            .set('title', action.settings.name)
            .setIn(['props', 'error', 'show'], false)
            .removeIn(['props', 'message'])
            .setIn(['props', 'table'], fromJS(action.settings)),
        );
    case actions.UPDATE_SQL_TABLE:
      return state.update(action.settings.name, (tab = hashMap()) =>
        tab
          .set('title', action.settings.name)
          .setIn(['props', 'table'], fromJS(action.settings)),
      );
    case actions.UPDATE_SQL_TABLE_NAMES:
      return state.update((s: any) =>
        s.mergeDeep(
          mapValues(mapKeys(action.tableNames), (title) => ({
            title,
            extraClass: 'animation--flash',
            props: {},
          })),
        ),
      );
    default:
      return state;
  }
};
