// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import request from 'universal-rx-request';

request.importRxExtensions();

// leave it first in case other actions are dispatched to stop the backend
export * from './sentryCatcher';
export * from './backendSession';
export * from './backendSessionJsonRpc';
export * from './datawarehouseSession';
export * from './logs';
export * from './course';
export * from './exercises';
export * from './location';
export * from './externalCalls';
export * from './settings';
export * from './timetracker';
export * from './window';
export * from './mapMuxOutputsToConsole';
export * from './fitnessFunctions';
export * from './externalExercise';
export * from './boot';
export * from './array';
export * from './groupAiSettings';
