import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import ApiClient from '../../helpers/ApiClient';
import { getMainAppApiClient } from '../../helpers/getClients';
import { notifyHostApplicationOfExerciseCompletion } from '../../helpers/learningMode';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { selectExercise, selectLearningMode } from '../selectors';

import { tryNavigateToNextExercise } from './exercises/exercises';
import { submitToMainApp } from './externalCalls';

const mainAppClient = getMainAppApiClient();
const videoExerciseType = videoExerciseConstants.exercises.VIDEO_EXERCISE;

/**
 * This does not support reducedOutline courses (for example EdX)
 */

export const epicNextExternalExercise = (
  action$: any,
  store: any,
  history: any,
) => {
  const handler = () => {
    const state = store.getState();
    const nextPath = selectors.selectNeighborsExercises(state).nextExercise;
    return [
      actions.setNextPath(nextPath),
      ...tryNavigateToNextExercise({
        state,
        history,
        nextPath,
      }),
    ];
  };
  return action$.ofType(actions.NEXT_EXTERNAL_EXERCISE).switchMap(() => {
    const state = store.getState();
    const exercise = selectExercise(state);

    if (selectLearningMode(state) === 'singleExercise') {
      if (exercise.get('type') !== videoExerciseType) {
        notifyHostApplicationOfExerciseCompletion(exercise.get('id'));
      }
      return Rx.Observable.empty();
    }

    const exType = selectors.selectExercise(state)?.get('type');
    const progress = selectors.selectGlobalUserProgress(state);
    const shouldSeeRating = selectors.selectShouldSeeChapterRating(state);
    const completedFirstTime =
      (progress.chapter.completed || progress.course.completed) &&
      shouldSeeRating;

    /**
     * Only when completedFirstTime does epicSubmitExternalExercise emit shouldShowWindow with a delay
     * Otherwise shouldShowWindow is emitted before nextExternalExercise / before reaching this code
     */
    if (exType === videoExerciseType && completedFirstTime) {
      return action$
        .ofType(actions.SHOULD_SHOW_WINDOW)
        .take(1)
        .switchMap(handler);
    }
    return handler();
  });
};

export const epicSubmitExternalExercise = (
  action$: any,
  store: any,
  history: any,
) => {
  return action$
    .ofType(actions.SUBMIT_EXTERNAL_EXERCISE)
    .concatMap(({ code, correct, message, xp }: any) => {
      const state = store.getState();
      const isUserLoggedIn = selectors.selectIsUserLoggedIn(state);
      const result$ = isUserLoggedIn
        ? submitToMainApp({ message, code, correct, xp }, store, history)
        : Rx.Observable.of(
            actions.showModal({ modal: selectors.MODAL_TYPE.LOGIN }),
          );

      if (!correct) {
        return result$;
      }

      const progress = selectors.selectGlobalUserProgress(state);
      const shouldSeeRating = selectors.selectShouldSeeChapterRating(state);
      const completedFirstTime =
        (progress.chapter.completed || progress.course.completed) &&
        shouldSeeRating;

      if (completedFirstTime) {
        const chapterId = selectors.selectChapterId(state);
        const courseId = selectors.selectCourse(state).get('id');
        return result$.merge(
          mainAppClient
            .execute(
              ApiClient.endpoint.window({
                type: 'chapter_completed',
                chapter_id: chapterId,
                course_id: courseId,
              }),
              actions.epicGotWindowResponse(),
            )
            .catch(() => [actions.shouldShowWindow({ type: 'none' })]),
        );
      }
      return result$.merge([actions.shouldShowWindow({ type: 'none' })]);
    });
};
