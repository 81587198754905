/* eslint-disable no-param-reassign */

import { fromJS, List as list, Map as hashMap } from 'immutable';
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import pickBy from 'lodash/pickBy';
import result from 'lodash/result';
import uniqueId from 'lodash/uniqueId';

import { outputsForConsole } from '../../../helpers/outputs';
import { activateTab, addTab } from '../../../helpers/tabs';
import * as actions from '../../actions';
import { Action, AddTabAction, emptyAction } from '../../actions';

import consoleSqlTabs from './consoleSqlTabs';
import {
  activate as activateFeedbackMessages,
  complete as completeExercise,
  markActiveFeedbackAsRated,
  update as updateFeedbackMessages,
  updateWithAssistantFeedbackMessage,
} from './feedbackMessages';
import { showHint, showSolution } from './hintAndSolution';
import initialState from './userInitialState';

const addSource = (src: any) => (props = hashMap()) => {
  const newProps = props.update('sources', (sources = list()) =>
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    sources.push(
      src
        .set('id', uniqueId('key:'))
        .set('isExpanded', false)
        .set('expandedHasFocus', false)
        .set('expandedPath', null),
    ),
  );
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const newIndex = newProps.get('sources').size - 1;
  return newProps.set('currentIndex', newIndex);
};

const setSource = (src: any) => (props = hashMap()) => {
  const newProps = props.set('sources', list().push(src));
  return newProps.set('currentIndex', 0);
};

const updateMarkdownOutputs = (
  state: any,
  src: any,
  extension: any,
  title: any,
) =>
  state
    .setIn(['outputMarkdownTabs', extension, 'title'], title)
    .updateIn(['outputMarkdownTabs', extension, 'props'], addSource(src))
    .update('outputMarkdownTabs', activateTab(extension));

export const solutionKeyForMarkdown = 'solution_';

export default (
  state: any,
  exercise: any,
  progress: any,
  action: Action = emptyAction,
) => {
  if (!exercise) {
    return null;
  }
  // because of COMPLETED_EXERCISE_AFTERWARDS
  if (!state || state.size < 2) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    state = initialState(exercise, progress).mergeDeep(state);
  }

  state = state.update('consoleSqlTabs', (tabs: any) =>
    consoleSqlTabs(tabs, exercise, action),
  );

  switch (action.type) {
    case actions.UPDATE_OBJECT_VIEW:
      return state.update(
        'consoleObjectViewTabs',
        (consoleObjectViewTabs: any) =>
          consoleObjectViewTabs.update(
            action.settings.name,
            (tab = hashMap()) =>
              tab
                .set('title', `Expected ${action.settings.name}`)
                .setIn(['props', 'table'], fromJS(action.settings)),
          ),
      );
    case actions.UPDATE_CURRENT_EXERCISE:
      return state
        .update((s: any) =>
          s
            .set(
              'consoleSqlTabs',
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
              initialState(exercise, progress).get('consoleSqlTabs'),
            )
            .setIn(['completed', 'show'], false),
        )
        .set(
          'consoleTabs',
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          initialState(exercise, progress).get('consoleTabs'),
        )
        .set(
          'graphicalTabs',
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          initialState(exercise, progress).get('graphicalTabs'),
        )
        .set(
          'outputMarkdownTabs',
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          initialState(exercise, progress).get('outputMarkdownTabs'),
        )
        .deleteIn(['editorTabs', 'html'])
        .delete('shellProxy');
    case actions.EPIC_START_SESSION: // Reset values if new session
      return state.update((s: any) =>
        result(action, 'initCommandOptions.command') === 'soft_init'
          ? s
          : s.delete('shellProxy'),
      );
    case actions.UPDATE_CONSOLE_CODE:
      return state.update('consoleTabs', activateTab('console'));
    case actions.SUBMIT_EXTERNAL_EXERCISE:
      return state.setIn(['completed', 'completed'], action.correct);
    case actions.COMPLETE_EXERCISE:
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return completeExercise(state, action, exercise)
        .set('sidePanelIsClosed', false)
        .set('isNavigatingToNextExercise', false)
        .set('nextPath', undefined);
    case actions.HIDE_COMPLETED_EXERCISE: {
      if (action.id !== exercise.get('id')) {
        return state;
      }
      return state.setIn(['completed', 'show'], false);
    }
    case actions.UPDATE_HIGHLIGHT:
      return state.updateIn(
        ['editorTabs', state.get('lastSubmitActiveEditorTab')],
        (editorTab: any) =>
          isNil(editorTab)
            ? editorTab
            : // @ts-expect-error ts-migrate(2339) FIXME: Property 'output' does not exist on type '{}'.
              editorTab.setIn(['props', 'lineErrors'], action.output.payload),
      );
    case actions.UPDATE_FEEDBACK_MESSAGE:
      return updateFeedbackMessages(state, action, exercise).set(
        'sidePanelIsClosed',
        false,
      );
    case actions.UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT:
      return updateWithAssistantFeedbackMessage(state, action.error).set(
        'sidePanelIsClosed',
        false,
      );
    case actions.UPDATE_FEEDBACK_TAB:
      return activateFeedbackMessages(state, action).set(
        'sidePanelIsClosed',
        false,
      );
    case actions.EPIC_SUBMIT_FEEDBACK:
      return markActiveFeedbackAsRated(state);
    case actions.UPDATE_EXTERNAL_EXERCISE_XP:
      return state.set('currentXp', action.currentXp);
    case actions.SET_HINT_SHOWN:
      return state.set('isHintShown', action.isHintShown);
    case actions.SHOW_HINT:
      return showHint(state, exercise, action);
    case actions.SHOW_SOLUTION:
      return showSolution(state, exercise, solutionKeyForMarkdown, action).set(
        'sidePanelIsClosed',
        false,
      );
    case actions.CHOOSE_ANSWER:
      return state.set('multipleChoiceAnswer', action.choice);
    case actions.SET_MCE_CHOICES_FOCUS:
      return state.set('choicesFocused', action.focused);
    case actions.RESULT_EXERCISE: {
      const shouldShowConsole = !isEmpty(
        pickBy(action.results, (obj) => includes(outputsForConsole, obj.type)),
      );
      return state
        .update((user: any) => {
          let userResult = user;
          forEach(action.results, (actionResult) => {
            userResult = userResult.update(
              actionResult.type,
              (value = list()) => value.push(actionResult.payload),
            );
            userResult = userResult.update('responses', (value = list()) =>
              value.push(actionResult),
            );
          });
          return userResult;
        })
        .update('bottomPanelIsClosed', (isClosed: any) =>
          shouldShowConsole ? false : isClosed,
        )
        .update('consoleTabs', (consoleTabs: any) =>
          shouldShowConsole ? activateTab('console')(consoleTabs) : consoleTabs,
        );
    }
    case actions.RERENDER_PLOT:
      return state.mergeIn(
        ['graphicalTabs', 'plot', 'props', 'sources', action.index],
        { path: action.url, isRelativeToMultiplexer: false },
      );
    case actions.UPDATE_CURRENT_GRAPHICAL_OUTPUT:
      return state.setIn(
        [action.category, action.key, 'props', 'currentIndex'],
        action.index,
      );
    case actions.EXPAND_GRAPHICAL_OUTPUT: {
      const index = state
        .getIn([action.category, action.key, 'props', 'sources'])
        .findIndex((source: any) => source.get('id') === action.id);
      if (index < 0) {
        return state;
      }
      return state.mergeIn(
        [action.category, action.key, 'props', 'sources', index],
        { isExpanded: true, expandedHasFocus: true, expandedPath: null },
      );
    }
    case actions.FOCUS_GRAPHICAL_OUTPUT: {
      const index = state
        .getIn([action.category, action.key, 'props', 'sources'])
        .findIndex((source: any) => source.get('id') === action.id);
      if (index < 0) {
        return state;
      }
      return state.mergeIn(
        [action.category, action.key, 'props', 'sources', index],
        { expandedHasFocus: true },
      );
    }
    case actions.BLUR_GRAPHICAL_OUTPUT: {
      const index = state
        .getIn([action.category, action.key, 'props', 'sources'])
        .findIndex((source: any) => source.get('id') === action.id);
      if (index < 0) {
        return state;
      }
      return state.mergeIn(
        [action.category, action.key, 'props', 'sources', index],
        { expandedHasFocus: false },
      );
    }
    case actions.CLOSE_GRAPHICAL_OUTPUT: {
      const index = state
        .getIn([action.category, action.key, 'props', 'sources'], list())
        .findIndex((source: any) => source.get('id') === action.id);
      if (index < 0) {
        return state;
      }
      return state.mergeIn(
        [action.category, action.key, 'props', 'sources', index],
        { isExpanded: false },
      );
    }
    case actions.FIGURE_EXPANDED:
      return state.setIn(
        [
          action.category,
          action.tabKey,
          'props',
          'sources',
          action.index,
          'expandedPath',
        ],
        action.src,
      );
    case actions.NEW_PLOT:
      return state
        .updateIn(
          ['graphicalTabs', 'plot', 'props'],
          addSource(
            hashMap({
              type: 'plot',
              path: action.url,
              isRelativeToMultiplexer: false,
            }),
          ),
        )
        .update('graphicalTabs', activateTab('plot'));
    case actions.ADD_PROXY:
      return state.set('proxyId', action.proxyId);
    case actions.NEW_SHELL_PROXY:
      return state.set('shellProxy', `${action.payload}`);
    case actions.NEW_PROXY: {
      const src = hashMap({
        type: 'iframe',
        path: action.payload,
        isRelativeToMultiplexer: true,
      });
      if (exercise.get('type') === 'MarkdownExercise') {
        return updateMarkdownOutputs(state, src, 'html', 'HTML Viewer');
      }
      return state
        .updateIn(['editorTabs', 'html', 'props'], setSource(src))
        .update('editorTabs', activateTab('html'))
        .setIn(['editorTabs', 'html', 'title'], 'HTML Viewer');
    }
    case actions.NEW_HTML: {
      let src = hashMap({
        type: 'iframe',
        path: action.src,
        isRelativeToMultiplexer: action.isRelativeToMultiplexer,
      });
      if (isNil(src.get('path'))) {
        src = src.set('content', action.html).set('isRawContent', true);
      }
      if (exercise.get('type') === 'MarkdownExercise') {
        return updateMarkdownOutputs(state, src, 'html', 'HTML Viewer');
      }

      return state
        .updateIn(['graphicalTabs', 'html', 'props'], addSource(src))
        .update('graphicalTabs', activateTab('html'));
    }
    case actions.NEW_PDF_DEPRECATED: {
      return updateMarkdownOutputs(
        state,
        hashMap({
          type: 'iframe',
          path: action.src,
          isRelativeToMultiplexer: false,
        }),
        'pdf',
        'PDF Viewer',
      );
    }
    case actions.NEW_PDF: {
      return updateMarkdownOutputs(
        state,
        hashMap({
          type: 'pdf',
          path: action.src,
          isRelativeToMultiplexer: action.isRelativeToMultiplexer,
        }),
        'pdf',
        'PDF Viewer',
      );
    }
    case actions.SHOW_BOKEH: {
      let src = hashMap({ type: 'iframe' });
      if (isNil(action.src)) {
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'true' is not assignable to type 'string'.
        src = src.merge({ isRawContent: true, content: action.html });
      } else {
        src = src.merge({
          path: action.src,
          // @ts-expect-error boolean is not assignable to string
          isRelativeToMultiplexer: false,
          // @ts-expect-error boolean is not assignable to string
          isRawContent: false,
        });
      }
      return state
        .updateIn(['editorTabs', 'html', 'props'], addSource(src))
        .update('editorTabs', activateTab('html'))
        .setIn(['editorTabs', 'html', 'title'], 'HTML Viewer');
    }
    case actions.SHOW_R_DOC:
      return state
        .setIn(
          ['editorTabs', 'rdoc'],
          fromJS({
            title: 'RDocumentation',
            extraClass: 'animation--flash',
            props: { path: action.payload },
          }),
        )
        .update('editorTabs', activateTab('rdoc'));
    case actions.UPDATE_MARKDOWN_ACTIVE_TITLE:
      return state.setIn(['markdown', 'activeTitle'], action.activeTitle);
    case actions.UPDATE_ACTIVE_TAB:
      return state
        .update(action.category, activateTab(action.activeKey))
        .update('bottomPanelIsClosed', (isClosed: any) =>
          !includes(['consoleTabs', 'consoleSqlTabs'], action.category)
            ? isClosed
            : false,
        );
    case actions.ADD_TAB: {
      const { category, key, title } = action as AddTabAction;
      return state
        .update(
          category,
          addTab({
            key,
            title,
          }),
        )
        .update(category, activateTab(key));
    }
    case actions.SET_LTI_STATUS:
      return state.set('ltiStatus', {
        status: action.status,
        message: action.message,
      });
    case actions.NEXT_INTERNAL_EXERCISE:
      return state.set('isNavigatingToNextExercise', true);
    case actions.SET_NEXT_PATH:
      return state.set('nextPath', action.nextPath);
    case actions.UPDATE_SQL_OPTIONS:
      return state.set('sqlOptions', fromJS(action.settings));
    // TODO: HANDLE_RESIZE
    // Listening that action is not nevessary as soon as the resize command works
    case actions.RESIZE_EDITORS:
      if (state.getIn(['graphicalTabs', 'plot', 'dimension', 'isRealSize'])) {
        return state;
      }
      return state.updateIn(
        ['graphicalTabs', 'plot', 'dimension'],
        (dimension: any) => {
          if (has(action.components, 'top-right-stack')) {
            return dimension.merge({
              width: action.components['top-right-stack'].innerWidth(),
              height: action.components['top-right-stack'].innerHeight() - 52,
            });
          }
          if (has(action.components, 'top-left-stack')) {
            return dimension.merge({
              width: Math.round(
                (action.components['top-left-stack'].innerWidth() -
                  action.dimensions.borderWidth) /
                  2,
              ),
              height: action.components['top-left-stack'].innerHeight() - 52,
            });
          }
          if (has(action.components, 'bottom-stack')) {
            return dimension.merge({
              width: action.components['bottom-stack'].innerWidth(),
              height: Math.round(
                (action.components['bottom-stack'].innerHeight() -
                  action.dimensions.borderWidth -
                  action.dimensions.headerHeight) /
                  2 -
                  52,
              ),
            });
          }
          return dimension.merge({ width: 640, height: 540 });
        },
      );

    case actions.EPIC_SUBMIT_CODE:
      return state
        .updateIn(
          ['graphicalTabs', 'plot', 'props', 'currentIndex'],
          (currentIndex: any) =>
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type '{}'.
            action.settings.command === 'resize'
              ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type '{}'.
                action.settings.figureIndex
              : currentIndex,
        )
        .updateIn(['graphicalTabs', 'plot', 'dimension'], (dimension: any) => {
          const { height, width } = action;
          if (
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type '{}'.
            action.settings.command === 'resize' &&
            isNumber(width) &&
            isNumber(height)
          ) {
            return dimension.merge({ isRealSize: true, width, height });
          }
          return dimension;
        })
        .update('lastSubmittedCode', (code: any) => {
          if (action.settings.command === 'submit') {
            return action.settings.code;
          }
          return code;
        })
        .set('lastRunCode', action.settings.code)
        .set(
          'lastSubmitActiveEditorTab',
          state
            .get('editorTabs')
            .findKey((editorTab: any) =>
              editorTab.getIn(['props', 'active'], false),
            ),
        );
    case actions.UPDATE_ATTEMPTS:
      return state.set('nr_attempts', action.nbAttempts);
    case actions.SET_SIDE_PANEL_CLOSED_STATE:
      return state.set('sidePanelIsClosed', action.closed);
    case actions.SET_BOTTOM_PANEL_CLOSED_STATE:
      return state.set('bottomPanelIsClosed', action.closed);
    case actions.CLOSE_EDITOR_TAB:
      if (state.hasIn(['editorTabs', action.tabKey])) {
        return state.deleteIn(['editorTabs', action.tabKey]);
      }
      return state;
    case actions.SET_USER_USED_AI_INCORRECT_SUBMISSIONS:
      return state.setIn(
        ['usedAiFeatures', 'aiIncorrectSubmissions'],
        action.value,
      );
    case actions.SET_USER_USED_AI_ERROR_EXPLANATION:
      return state.setIn(
        ['usedAiFeatures', 'aiErrorExplanation'],
        action.value,
      );
    default:
      return state;
  }
};
