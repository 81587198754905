import { initSplitClient } from '../../../helpers/featureFlags';
import { USER_STATUS } from '../../selectors/user';

export default async function initSplitAsPromise({
  courseAsPromise,
  overwrittenFeatureFlags,
  userAsPromise,
}: any) {
  const user = await userAsPromise;
  let userId = null;
  let hasActiveSubscription = false;
  if (user != null && user.status === USER_STATUS.LOGIN) {
    userId = user.settings.id;
    hasActiveSubscription = user.settings.has_active_subscription;
  }

  let courseId;
  try {
    const course = await courseAsPromise;
    courseId = course.id;
  } catch (error) {
    courseId = null;
  }

  return initSplitClient({
    userId,
    hasActiveSubscription,
    courseId,
    overrides: overwrittenFeatureFlags,
  }).catch(() => {});
}
