import { fromJS } from 'immutable';

import { getUserEnabledForAiFlags } from '../../helpers/ai/getUserEnabledForAiFlags';
import * as actions from '../actions';
import { userEnabledForAiFeaturesFetched } from '../actions';
import * as selectors from '../selectors';

export const epicFetchGroupsAiSettings = (action$: any) =>
  action$
    .ofType(actions.BOOT_SUCCEEDED)
    .filter(
      (action: any) =>
        action.entities.user.status === selectors.USER_STATUS.LOGIN,
    )
    .switchMap(async (action: any) => {
      if (action.entities?.user?.settings?.groups?.length === 0) {
        return userEnabledForAiFeaturesFetched(
          fromJS({
            aiErrorExplanationEnabled: true,
            aiSolutionExplanationEnabled: true,
          }),
        );
      }
      const userEnabledForAiFlags = await getUserEnabledForAiFlags();
      return userEnabledForAiFeaturesFetched(fromJS(userEnabledForAiFlags));
    });
