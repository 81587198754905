import { fromJS, Map as hashMap } from 'immutable';
import find from 'lodash/find';

import * as actions from '../actions';

export default (state = hashMap(), action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      return state.mergeDeepIn(
        ['current'],
        fromJS(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entities' does not exist on type '{}'.
          find(action.entities.course.chapters, { id: action.chapterId }),
        ),
      );
    case actions.UPDATE_CHAPTER:
      return state.set(
        'current',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapters' does not exist on type '{}'.
        action.chapters.find(
          (chapter: any) =>
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapterRef' does not exist on type '{}'.
            action.chapterRef === chapter.get('slug') ||
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapterRef' does not exist on type '{}'.
            Number(action.chapterRef) === Number(chapter.get('id')),
        ),
      );
    case actions.FIRST_TIME_COMPLETED_CHAPTERS: {
      const currentChapter = state.getIn(['current', 'id']);
      return state.update(
        'firstTimeCompletedChapters',
        (firstTimeCompletedChapters = hashMap()) =>
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          firstTimeCompletedChapters.set(currentChapter, true),
      );
    }
    case actions.COMPLETE_EXERCISE:
      return state.set('window', fromJS({ type: 'fetching' }));
    case actions.REMOVE_WINDOW:
      return state.setIn(['window', 'show'], false);
    case actions.SHOW_WINDOW:
      return state.setIn(['window', 'show'], true);
    case actions.SHOULD_SHOW_WINDOW:
      return state.set(
        'window',
        fromJS({
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          type: action.windowType,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          url: action.url,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          meta: action.meta,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          isCourseEndReached: action.isCourseEndReached ?? false,
        }),
      );
    default:
      return state;
  }
};
