// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import rxRequest from 'universal-rx-request';

import { technologyById } from '../../helpers/technology';
import { trackCourseCompleted } from '../../helpers/trackProgressGtm';
import * as actions from '../actions';
import * as selectors from '../selectors';

const { STATUS: REQUEST_STATUS, getStatus } = rxRequest;

export const epicWindow = (action$: any, store: any) =>
  action$
    .ofType(getStatus(actions.EPIC_GOT_WINDOW_RESPONSE, REQUEST_STATUS.SUCCESS))
    .switchMap((action: any) => {
      const { body } = action.data;
      const state = store.getState();
      const {
        id: courseId,
        technology_id,
        time_needed_in_hours,
        title,
        topic_id,
        xp: courseXp,
      } = selectors.selectCourse(state).toJS();
      const technology =
        technologyById[technology_id as keyof typeof technologyById];

      const isCourseEndReached = body.url?.includes('end_reached');

      if (isCourseEndReached) {
        trackCourseCompleted({
          courseId,
          title,
          courseXp,
          technology,
          time_needed_in_hours,
          topic_id,
        });
      }

      let obs$ = Rx.Observable.of(
        actions.shouldShowWindow({
          type: body.type,
          url: body.url,
          meta: body.meta,
          isCourseEndReached,
        }),
      );

      switch (body.type) {
        case 'dialog':
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; modal: any; }' is missing th... Remove this comment to see the full error message
          obs$ = obs$.concat(
            Rx.Observable.of(
              actions.showModal({
                modal: {
                  ...selectors.MODAL_TYPE.IFRAME,
                  url: body.url,
                  meta: body.meta,
                  onExit: action.blockInteraction ? () => {} : null,
                },
              }),
            ),
          );
          break;
        default:
          break;
      }

      return obs$;
    });
