import { fromJS, Map as HashMap } from 'immutable';
import isEmpty from 'lodash/isEmpty';

import * as actions from '../actions';
import { Action } from '../actions';

const getChapterIndexFromRef = (chapters: any, chapterRef: any) =>
  chapters.findIndex(
    (chapter: any) =>
      chapterRef === chapter.get('slug') ||
      Number(chapterRef) === Number(chapter.get('id')),
  );

export type CourseState = HashMap<string, unknown>;

const defaultCourseState: CourseState = HashMap();

export default (
  state: CourseState = defaultCourseState,
  action: Action,
): CourseState => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      return state
        .merge(fromJS(action.entities.course))
        .update((currentState) =>
          action.entities.courseProgress
            ? currentState.update('progress', (progress = HashMap()) =>
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                progress.mergeDeep(fromJS(action.entities.courseProgress)),
              )
            : currentState,
        );
    case actions.UPDATE_EXERCISES_IN_CHAPTER: {
      const chapterIndex = getChapterIndexFromRef(
        state.get('chapters'),
        action.chapterRef,
      );
      if (chapterIndex > -1) {
        return state
          .setIn(['chapters', chapterIndex, 'exercises'], action.exercises)
          .setIn(['chapters', chapterIndex, 'progress'], action.progress);
      }
      return state;
    }
    case actions.EPIC_GET_EXERCISES_FROM_CHAPTER: {
      const chapterIndex = getChapterIndexFromRef(
        state.get('chapters'),
        action.chapterRef,
      );
      return state
        .deleteIn(['chapters', chapterIndex, 'exercises'])
        .deleteIn(['chapters', chapterIndex, 'progress']);
    }
    case actions.UPDATE_COURSE_PROGRESS:
      if (action.data.status === 'failed' || isEmpty(action.data)) {
        return state;
      }
      return state.set('progress', fromJS(action.data));
    default:
      return state;
  }
};
