import { List as list, Map as hashMap } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import { createSelector } from 'reselect';

import { getOverridesForExercise } from '../../../helpers/contentExperiments/overrides';
import isExternalExerciseType from '../../../helpers/isExternalExerciseType';
import { getGlobalProgressState } from '../../../helpers/userProgress';
import { selectChapter } from '../chapter';

import { selectExercises } from './base';
import { getHintOrSolution } from './hintAndSolution';

export const MessageType = {
  HINT: 'HINT',
  INCORRECT: 'INCORRECT',
  SOLUTION: 'SOLUTION',
  ASSISTANT: 'ASSISTANT',
};

export const selectExerciseIndex = (state: any) =>
  state.getIn(['exercises', 'current'], null);
export const selectExercise = createSelector(
  selectExercises,
  selectExerciseIndex,
  (baseExercises: any, exerciseIndex: any) => {
    const currentExercise = baseExercises.get(exerciseIndex);
    if (!currentExercise) return hashMap();
    const currentExerciseId = currentExercise.get('id');
    return currentExercise.merge(getOverridesForExercise(currentExerciseId));
  },
);

export const selectSubExercises = (state: any) =>
  selectExercise(state).get('subexercises') || list();
export const selectCurrentSubExercise = (state: any) =>
  selectSubExercises(state).findLast((ex: any) => ex.get('active')) ||
  selectSubExercises(state).get(0) ||
  hashMap();
export const selectCurrentExOrSubEx = createSelector(
  selectCurrentSubExercise,
  selectExercise,
  (sub, ex) => (sub.isEmpty() ? ex : sub),
);
export const selectNextSubExercise = (state: any) =>
  selectSubExercises(state).get(
    selectCurrentSubExercise(state).get('number'),
  ) || hashMap();
export const selectUserExercise = (state: any) =>
  selectExercise(state).get('user', hashMap());
export const selectFeedbackMessages = createSelector(
  selectCurrentSubExercise,
  selectExercise,
  (sub, ex) =>
    (sub.size > 0 ? sub : ex.get('user', hashMap()))
      .get('feedbackMessages', list())
      .map((feedbackMessage: any) => {
        if (feedbackMessage.messageType === MessageType.HINT) {
          const hint = (sub.isEmpty() ? ex : sub).get('hint');
          return {
            ...feedbackMessage,
            body: isEmpty(hint) ? 'No hint available' : hint,
          };
        }
        return feedbackMessage;
      }),
);
export const selectExercisesProgress = (state: any) =>
  state.getIn(['exercises', 'progress']);
export const selectExerciseProgress = (state: any) => {
  const exerciseId = selectExercise(state).get('id');
  const progress = state.getIn(['exercises', 'progress']) || list();
  return (
    progress.find((ex: any) => ex.get('exercise_id') === exerciseId) ||
    hashMap()
  );
};

export const selectGlobalUserProgress = createSelector(
  selectExercise,
  selectExercises,
  selectExercisesProgress,
  (state) => state.getIn(['course', 'progress']),
  (state) => selectChapter(state).get('id'),
  getGlobalProgressState,
);
export const selectNbAttempts = createSelector(
  (state) => selectExerciseProgress(state).get('nr_attempts'),
  (state) => selectUserExercise(state).get('nr_attempts'),
  (progressAttempts, userExAttempts) =>
    max([progressAttempts, userExAttempts, 0]),
);
export const selectCompleted = (state: any) =>
  selectUserExercise(state).get('completed', hashMap());
export const selectLastCode = (state: any) =>
  selectUserExercise(state).get('lastSubmittedCode', null);
export const selectShellProxy = (state: any) =>
  selectUserExercise(state).get('shellProxy') ||
  selectCurrentSubExercise(state).get('shellProxy');
export const selectOriginalEditorTabs = (state: any) =>
  selectUserExercise(state).get('editorTabs', hashMap());
export const selectConsoleTabs = (state: any) =>
  selectUserExercise(state).get('consoleTabs', hashMap());
export const selectGraphicalTabs = (state: any) =>
  selectUserExercise(state).get('graphicalTabs', hashMap());
export const selectConsoleSqlTabs = (state: any) =>
  selectUserExercise(state).get('consoleSqlTabs', hashMap());
export const selectObjectView = (state: any) =>
  selectUserExercise(state).get('consoleObjectViewTabs', hashMap());
export const selectInputMarkdownTabs = (state: any) =>
  selectUserExercise(state).get('inputMarkdownTabs', hashMap());
export const selectOutputMarkdownTabs = (state: any) =>
  selectUserExercise(state).get('outputMarkdownTabs', hashMap());
export const selectSqlOptions = (state: any) =>
  selectUserExercise(state).get('sqlOptions', hashMap());
export const selectBackendErrors = (state: any) =>
  selectUserExercise(state).get('backend-error', list());
export const selectIsExCompleted = (state: any) =>
  selectUserExercise(state).getIn(['completed', 'show'], false);
export const selectSidePanelClosedState = (state: any) =>
  selectUserExercise(state).get('sidePanelIsClosed', false);
export const selectBottomPanelClosedState = (state: any) =>
  selectUserExercise(state).get('bottomPanelIsClosed', false);
export const selectLtiStatus = (state: any) =>
  selectUserExercise(state).get('ltiStatus', { status: 'none' }).status;
export const selectIsNavigatingToNextExercise = (state: any) =>
  selectUserExercise(state).get('isNavigatingToNextExercise', false);
export const selectNextPath = (state: any) =>
  selectUserExercise(state).get('nextPath');
export const selectExerciseType = (state: any) =>
  selectExercise(state).get('type');
export const selectExerciseOrSubExType = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  (exercise, subExercise) => subExercise.get('type') ?? exercise.get('type'),
);
export const selectIsExternalExercise = createSelector(
  selectExerciseType,
  (exerciseType) => isExternalExerciseType(exerciseType),
);

export const selectMarkdown = (state: any) =>
  selectUserExercise(state).get('markdown', hashMap());
export const selectHintOrSolution = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  getHintOrSolution,
);

export const selectSolution = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  (exercise, subExercise) =>
    subExercise.get('solution') ?? exercise.get('solution'),
);
export const selectPEC = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  (exercise, subExercise) =>
    subExercise.get('pre_exercise_code') ?? exercise.get('pre_exercise_code'),
);
export const selectInstructions = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  (exercise, subExercise) =>
    subExercise.get('instructions') ?? exercise.get('instructions'),
);

const EXERCISE_TYPES_WITHOUT_CODE_SUBMISSION = ['MultipleChoiceExercise'];
const getCanSubmitExerciseType = (type: any) =>
  !EXERCISE_TYPES_WITHOUT_CODE_SUBMISSION.includes(type);
export const getCanSubmitExercise = (exercise: any, subExercise: any) =>
  getCanSubmitExerciseType(exercise.get('type')) &&
  getCanSubmitExerciseType(subExercise && subExercise.get('type'));
export const selectCanSubmitCurrentExercise = createSelector(
  selectExercise,
  selectCurrentSubExercise,
  getCanSubmitExercise,
);

export const selectCurrentExerciseXpWon = createSelector(
  selectExerciseProgress,
  selectCompleted,
  selectGlobalUserProgress,
  (exerciseProgress, completed, userProgress) => {
    const isCompleted = exerciseProgress.get('completed');
    const isFirstTime = completed.get('isFirstTime');
    return !isCompleted && isFirstTime ? userProgress.exercise.xp : 0;
  },
);
export const selectUserUsedAiIncorrectSubmissions = (state: any) =>
  selectUserExercise(state).getIn(
    ['usedAiFeatures', 'aiIncorrectSubmissions'],
    false,
  );
export const selectUserUsedAiErrorExplanation = (state: any) =>
  selectUserExercise(state).getIn(
    ['usedAiFeatures', 'aiErrorExplanation'],
    false,
  );
