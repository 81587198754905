import { SessionStatusCode } from '@datacamp/multiplexer-client';

import { unreachableWithReturn } from '../../interfaces/unreachableWithReturn';
import * as actions from '../actions';
import { Action, noopAction } from '../actions';
import { BACKEND_STATUS } from '../selectors';

type RecordValues<T> = T[keyof T];

type BackendStatus = RecordValues<typeof BACKEND_STATUS>;

export type BackendSessionState = {
  isInitSession: boolean;
  lastSubmittedCode: string | undefined;
  lastSubmittedCommand: string | undefined;
  message: string | null;
  sessionId: string | undefined;
  status: BackendStatus;
};

export const initialState: BackendSessionState = {
  status: BACKEND_STATUS.NONE,
  lastSubmittedCode: undefined,
  lastSubmittedCommand: undefined,
  isInitSession: false,
  message: null,
  sessionId: undefined,
};

const setStatus = (
  state: BackendSessionState,
  status: BackendStatus,
  statusCode: any,
) => {
  const isInitSession =
    state.status.code === BACKEND_STATUS.NONE.code &&
    status.code === BACKEND_STATUS.BUSY.code;
  return {
    ...state,
    isInitSession,
    status: {
      ...status,
      statusCode,
    },
  };
};

const getBackendStatus = (statusCode: SessionStatusCode) => {
  switch (statusCode) {
    case 'broken':
      return BACKEND_STATUS.BROKEN;
    case 'busy':
      return BACKEND_STATUS.BUSY;
    case 'none':
      return BACKEND_STATUS.NONE;
    case 'ready':
      return BACKEND_STATUS.READY;
    default:
      return unreachableWithReturn(statusCode, BACKEND_STATUS.NONE);
  }
};

export default (
  state = initialState,
  action: Action = noopAction,
): BackendSessionState => {
  switch (action.type) {
    case actions.UPDATE_BACKEND_STATUS: {
      return {
        ...setStatus(state, getBackendStatus(action.status), action.statusCode),
        message: action.message,
      };
    }
    case actions.EPIC_SUBMIT_CODE:
      return {
        ...state,
        lastSubmittedCode: action.settings.code,
        lastSubmittedCommand: action.settings.command,
      };
    // Output sent from the backend that means the backend (XWhat) crashed
    case actions.EPIC_BACKEND_ERROR:
      return setStatus(
        state,
        BACKEND_STATUS.BROKEN,
        500, // something went wrong
      );
    case actions.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    default:
      return state;
  }
};

export { BACKEND_STATUS };
