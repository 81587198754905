import { selectCourse } from './course';

export const selectIsMobilePopupLinkSending = (state: any) =>
  Boolean(state.getIn(['mobilePopup', 'linkSending']));

export const selectIsMobilePopupLinkSent = (state: any) =>
  Boolean(state.getIn(['mobilePopup', 'linkSent']));

export const selectIsMobilePopupLinkFailed = (state: any) =>
  Boolean(state.getIn(['mobilePopup', 'linkFailed']));

export const selectShouldShowMobileButton = (state: any) =>
  Boolean(selectCourse(state).get('mobile_enabled'));
