import { Map as hashMap } from 'immutable';

import * as actions from '../actions';

export default (state = hashMap(), action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.MOBILE_POPUP_LINK_SENDING:
      return state.set('linkSending', true).set('linkFailed', false);
    case actions.MOBILE_POPUP_LINK_SENT:
      return state
        .set('linkSent', true)
        .set('linkSending', false)
        .set('linkFailed', false);
    case actions.MOBILE_POPUP_LINK_FAILED:
      return state.set('linkSending', false).set('linkFailed', true);
    default:
      return state;
  }
};
