import { strapiApi } from '../../../helpers/api/clients';
import { normalize } from '../../../helpers/cms/normalize';
import { REQUEST_STATUS } from '../../selectors';

import createError from './createError';

const availableFacets = ['technology_array', 'topic_array'] as const;

type AvailableFacets = typeof availableFacets[number];

type Facet = {
  [key in AvailableFacets]?: string[];
};

export type CategoryPage = {
  facet: Facet;
  slug: string;
};

export type CategoryPages = CategoryPage[];

export default async ({
  preFetchedData,
}: {
  preFetchedData: Map<string, any>;
}): Promise<CategoryPages> => {
  const preFetchedDataRequest = preFetchedData.get('categoryPages');
  if (preFetchedDataRequest.get('status') === REQUEST_STATUS.SUCCESS) {
    return Promise.resolve(preFetchedDataRequest.get('data'));
  }
  return strapiApi
    .getCategoryPageSlugsAndFacets()
    .then((response) => {
      return normalize(response.body).categoryPages;
    })
    .catch((error) => {
      return Promise.reject(
        createError({ message: `Failed to get category page data: ${error}` }),
      );
    });
};
