import { fromJS } from 'immutable';

import * as actions from '../actions';

const initialState = fromJS({
  indicator: 'none',
  description: 'No status has been fetched from the Status Page.',
});

const setStatus = (state: any, status: any) =>
  state
    .set('indicator', status.indicator)
    .set('description', status.description);

export default (state = initialState, action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.UPDATE_SYSTEM_STATUS:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{}'.
      return setStatus(state, action.status);
    default:
      return state;
  }
};
