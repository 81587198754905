import isString from 'lodash/isString';
import replace from 'lodash/replace';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import { getMux } from '../../../helpers/pluginManager';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { BACKEND_STATUS } from '../../selectors';

export const epicGetLogs = (action$: any, store: any) =>
  action$.ofType(actions.UPDATE_BACKEND_STATUS).switchMap((action: any) => {
    if (action.status !== BACKEND_STATUS.READY.code) {
      return [];
    }
    return Rx.Observable.timer(2000, 2000)
      .filter(() => getMux() != null)
      .filter(
        () =>
          selectors.selectExerciseType(store.getState()) === 'ShinyExercise',
      )
      .do(() => {
        // @ts-expect-error to be checked if this command type is still valid
        getMux().input({ command: 'logs' });
      })
      .concatMapTo([]);
  });

export const epicAppendLogsToConsole = (action$: any) =>
  Rx.Observable.merge(
    action$
      .ofType(actions.REGISTER_CONSOLE_OUTPUT_CALLBACK)
      .combineLatest(
        action$.ofType(actions.APPEND_CONSOLE_LOG),
        (callbackAction: any, logAction: any) => ({
          callbackAction,
          logAction,
        }),
      ),
    action$.ofType(actions.EPIC_START_SESSION),
  )
    .scan((length, action) => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      if (action.type === actions.EPIC_START_SESSION) {
        return 0;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'callbackAction' does not exist on type '... Remove this comment to see the full error message
      const { callbackAction, logAction } = action;
      if (!isString(logAction.log)) {
        return length;
      }
      const fullPayload = replace(
        logAction.log,
        /(\x9B|\x1B\[)[0-?]*[ -/]*[@-~]/g, // eslint-disable-line no-control-regex
        '',
      );
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'number'.
      const payload = fullPayload.slice(length);
      if (payload) {
        callbackAction.callback([{ payload }]);
      }
      return fullPayload.length;
    }, 0)
    .concatMapTo([]);
