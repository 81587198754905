import { Reducer } from '../../interfaces/State';
import { BOOT_SUCCEEDED, IBootSucceedAction } from '../actions/boot';
import {
  EXERCISE_COMPLETED,
  ExerciseCompletedAction,
} from '../actions/exercises';

type StreakInfoUnknownState = {
  type: 'StreakUnknown';
};

type StreakInfoKnownState = {
  createdAt?: string;
  dailyXp: number;
  deadline: number;
  incrementedAt?: string;
  lengthInDays: number;
  type: 'StreakKnown';
};

export type StreakInfoState = StreakInfoUnknownState | StreakInfoKnownState;

const initialState: StreakInfoState = { type: 'StreakUnknown' };

const storeStreakInfo = (
  state: StreakInfoState,
  action: IBootSucceedAction,
): StreakInfoState => {
  const { streakInfo } = action.entities;
  if (streakInfo == null) {
    return state;
  }
  return {
    type: 'StreakKnown',
    dailyXp: streakInfo.daily_xp,
    lengthInDays: streakInfo.streak.days,
    createdAt: streakInfo.streak.created_at,
    incrementedAt: streakInfo.streak.last_incremented_at,
    deadline: Date.parse(streakInfo.streak_deadline),
  };
};

export const DAILY_XP_GOAL = 250;
const STREAK_DAY_GOAL = 5;
const STREAK_5DAY_BONUS = 0;
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

const updateDeadlineAndReachStreakImmediately = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
  streakLengthAfterDeadline: number,
): StreakInfoKnownState => {
  const willCross5DayStreak = streakLengthAfterDeadline + 1 === STREAK_DAY_GOAL;
  return {
    ...state,
    incrementedAt: new Date(action.currentTime || Date.now()).toISOString(),
    dailyXp: willCross5DayStreak
      ? action.xpGained + STREAK_5DAY_BONUS
      : action.xpGained,
    lengthInDays: streakLengthAfterDeadline + 1,
    deadline: state.deadline + MILLISECONDS_IN_DAY,
  };
};

const updateDeadline = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
  streakLengthAfterDeadline: number,
): StreakInfoKnownState => {
  return {
    ...state,
    dailyXp: action.xpGained,
    lengthInDays: streakLengthAfterDeadline,
    deadline: state.deadline + MILLISECONDS_IN_DAY,
  };
};

const handleExerciseCompletedAfterStreakDeadline = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
): StreakInfoKnownState => {
  const streakWasReachedBeforeDeadline = state.dailyXp >= DAILY_XP_GOAL;
  const willImmediatelyReachStreakAfterDeadline =
    action.xpGained >= DAILY_XP_GOAL;

  const streakLengthAfterDeadline = streakWasReachedBeforeDeadline
    ? state.lengthInDays
    : 0;

  if (willImmediatelyReachStreakAfterDeadline) {
    return updateDeadlineAndReachStreakImmediately(
      state,
      action,
      streakLengthAfterDeadline,
    );
  }

  return updateDeadline(state, action, streakLengthAfterDeadline);
};

const incrementDailyXp = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
): StreakInfoKnownState => {
  const didMissStreakDeadline = action.currentTime > state.deadline;
  if (didMissStreakDeadline) {
    return handleExerciseCompletedAfterStreakDeadline(state, action);
  }

  const newDailyXp = state.dailyXp + action.xpGained;
  const hasReachedDailyGoal =
    state.dailyXp < DAILY_XP_GOAL && newDailyXp >= DAILY_XP_GOAL;

  const newLengthInDays = hasReachedDailyGoal
    ? state.lengthInDays + 1
    : state.lengthInDays;

  const crossed5dayStreak =
    state.lengthInDays === STREAK_DAY_GOAL - 1 &&
    newLengthInDays === STREAK_DAY_GOAL;

  const newIncrementedAt = hasReachedDailyGoal
    ? new Date(action.currentTime || Date.now()).toISOString()
    : state.incrementedAt;

  return {
    ...state,
    incrementedAt: newIncrementedAt,
    dailyXp: crossed5dayStreak ? newDailyXp + STREAK_5DAY_BONUS : newDailyXp,
    lengthInDays: newLengthInDays,
  };
};

const streakInfoReducer: Reducer<StreakInfoState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BOOT_SUCCEEDED: {
      return storeStreakInfo(state, action);
    }
    case EXERCISE_COMPLETED:
      if (state.type === 'StreakUnknown') {
        return state;
      }
      return incrementDailyXp(state, action);
    default:
      return state;
  }
};
export default streakInfoReducer;
