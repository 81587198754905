import { Record } from 'immutable';

import config from '../../config';
import { Reducer } from '../../interfaces/State';
import * as actions from '../actions';
import { BOOT_STATE, BootState } from '../selectors';

type BootStateShape = {
  bootState: BootState;
  error: null | string;
};
export const BootStateRecord = Record<BootStateShape>(
  {
    bootState: BOOT_STATE.NONE,
    error: null,
  },
  'BootStateRecord',
);
export type BootReducerState = Record.Instance<BootStateShape>;

const initialState = new BootStateRecord({});

const bootReducer: Reducer<BootReducerState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      return state.update('bootState', () => {
        if (action.entities.course.lti_only && config.isServerSideRendering) {
          return BOOT_STATE.NONE;
        }
        return config.isServerSideRendering
          ? BOOT_STATE.PRE_BOOTED
          : BOOT_STATE.BOOTED;
      });
    case actions.BOOT_FAILED:
      return state
        .set('bootState', BOOT_STATE.BOOTED)
        .set('error', action.error);
    default:
      return state;
  }
};

export default bootReducer;
