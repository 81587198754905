import { Action, SessionOutput } from '../actions';

export type OutputState = {
  lastErrorMessage: string | null;
  output: SessionOutput[];
};

const initialState: OutputState = {
  lastErrorMessage: null,
  output: [],
};

export function findErrorMessageInOutputs(
  outputs: SessionOutput[],
): string | null {
  const errorOutput = outputs.find(
    (output) => output.type === 'error' || output.type === 'r-error',
  );
  if (errorOutput) {
    return errorOutput.payload;
  }
  return null;
}

export default (state = initialState, action: Action): OutputState => {
  switch (action.type) {
    case 'output/SET_CONSOLE_OUTPUT':
      return {
        output: [...state.output, ...action.output],
        lastErrorMessage: findErrorMessageInOutputs(action.output),
      };
    case 'output/CLEAR_CONSOLE_OUTPUT':
      return {
        output: [],
        lastErrorMessage: null,
      };
    default:
      return state;
  }
};
