// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
import languagesConfig, { getTabTitle } from '@datacamp/dc-languages-config';
import { Map as hashMap } from 'immutable';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import max from 'lodash/max';

import {
  isTabOrBulletConsoleEx,
  resetProgress as resetTabOrBulletConsole,
} from '../../../helpers/ComposedConsoleExercise';

import { updateSolutionFeedback } from './feedbackMessages';
import { showHint, updateSolutionTab } from './hintAndSolution';

export const setCurrentXp = (exercise: any) =>
  exercise.setIn(
    ['user', 'currentXp'],
    exercise
      .get('subexercises')
      .reduce(
        (acc: any, subEx: any) =>
          acc + max([subEx.get('xpWon'), subEx.get('progressXp'), 0]),
        0,
      ),
  );

const mergeProgress = (exercise: any, progressSubExercises: any) => {
  if (!progressSubExercises || progressSubExercises.isEmpty()) {
    return exercise;
  }
  if (exercise.get('subExProgressMerged')) {
    return exercise;
  }

  const isTabOrBulletConsole = isTabOrBulletConsoleEx(exercise.get('type'));

  // zip the list of sub-ex's progress with the same list but shifted, so for each item we have the current and the previous progress
  const progressSubExercisesShifted = progressSubExercises.zipWith(
    (currentSubExProgress: any, previousSubExProgress: any) => ({
      currentSubExProgress,
      previousSubExProgress,
    }),
    progressSubExercises.unshift(null),
  );
  let updatedEx = exercise
    .set('subExProgressMerged', true)
    .update('subexercises', (subexercises: any) =>
      subexercises.zipWith((subEx: any, { currentSubExProgress }: any) => {
        const progress = currentSubExProgress || hashMap();
        return subEx
          .setIn(['active'], false)
          .setIn(['unlocked'], progress.get('completed'))
          .setIn(['completed', 'completed'], progress.get('completed'))
          .setIn(['last_attempt'], progress.get('last_attempt'))
          .setIn(['used_solution'], progress.get('used_solution'))
          .setIn(['isSolutionShown'], progress.get('used_solution'))
          .setIn(['used_hint'], progress.get('used_hint'))
          .setIn(['isHintShown'], progress.get('used_hint'))
          .update((sub: any) =>
            sub.get('isHintShown') ? showHint(sub, sub, {}) : sub,
          )
          .update((sub: any) =>
            sub.get('isSolutionShown') && isTabOrBulletConsole
              ? updateSolutionFeedback(sub, sub.get('solution', ''))
              : sub,
          )
          .setIn(['progressXp'], progress.get('xp'))
          .setIn(
            ['xpToWin'],
            (() => {
              if (progress.get('completed')) {
                return progress.get('xp');
              }
              if (progress.get('used_solution')) {
                return 0;
              }
              if (progress.get('used_hint')) {
                return subEx.get('xp') * 0.7 || 0;
              }
              return subEx.get('xp');
            })(),
          );
      }, progressSubExercisesShifted),
    );

  const language = exercise.get('language');
  const config = get(languagesConfig, language, {});
  const tabTitle = getTabTitle(
    config.editorPrefixTitle,
    language,
    exercise.get('type'),
  );
  const scriptTabKey = `files/${tabTitle}`;

  // updated code for every sub ex
  updatedEx = updatedEx.update('subexercises', (subexercises: any) =>
    subexercises.map((subEx: any) => {
      const solution = subEx.get('solution');
      const sampleCode = subEx.get('sample_code');
      const usedSolution = subEx.get('used_solution');
      const lastAttempt = subEx.get('last_attempt');

      return subEx.updateIn(
        ['tabs', scriptTabKey, 'code'],
        (defaultCode: any) =>
          (usedSolution && lastAttempt === solution
            ? sampleCode
            : lastAttempt || sampleCode) || defaultCode,
      );
    }),
  );

  let subActiveEx = hashMap();
  // show the correct tab to the user
  updatedEx = updatedEx.update('subexercises', (subexercises: any) => {
    const lastCompletedKey = subexercises.findLastKey((subEx: any) =>
      subEx.getIn(['completed', 'completed']),
    );
    let activeKey = isNumber(lastCompletedKey) ? lastCompletedKey + 1 : 0;
    activeKey = activeKey >= subexercises.size ? 0 : activeKey;
    subActiveEx = subexercises.get(activeKey);
    return subexercises
      .setIn([activeKey, 'unlocked'], true)
      .setIn([activeKey, 'active'], true);
  });

  if (subActiveEx.get('isSolutionShown') && !isTabOrBulletConsole) {
    updatedEx = updatedEx.update('user', (user: any) =>
      updateSolutionTab(
        user,
        subActiveEx,
        updatedEx.get('language'),
        'solutionReadOnly',
      ),
    );
  }

  if (
    updatedEx
      .get('subexercises')
      .reduce(
        (acc: any, subEx: any) =>
          acc && subEx.getIn(['completed', 'completed']),
        true,
      )
  ) {
    updatedEx = updatedEx.setIn(['user', 'completed', 'completed'], true);
    updatedEx = setCurrentXp(updatedEx);
  }

  if (isTabOrBulletConsole) {
    updatedEx = resetTabOrBulletConsole(updatedEx);
  }

  return updatedEx;
};

const mergeInitialState = (exercise: any) => {
  if (exercise.get('subExSet')) {
    return exercise;
  }
  return exercise
    .set('subExSet', true)
    .update('subexercises', (subexercises: any) =>
      subexercises.map((subEx: any) => {
        return subEx.setIn(['xpToWin'], subEx.get('xp', 0));
      }),
    );
};

export default (exercise: any, progressSubExercises: any) => {
  if (!exercise || !exercise.get('subexercises')) {
    return exercise;
  }
  return mergeProgress(mergeInitialState(exercise), progressSubExercises);
};
