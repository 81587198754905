// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import ApiClient from '../../helpers/ApiClient';
import {
  getCampusApiClient,
  getMainAppApiClient,
} from '../../helpers/getClients';
import { isTeachPreview } from '../../helpers/isTeachPreview';
import { scrubAPITokens } from '../../helpers/scrubAPITokens';
import * as actions from '../actions';
import * as selectors from '../selectors';

export const epicSubmitFeedback = (action$: any, store: any, history: any) =>
  action$.ofType(actions.EPIC_SUBMIT_FEEDBACK).concatMap((action: any) => {
    const state = store.getState();
    const subExercise = selectors.selectCurrentSubExercise(state);
    const exercise = selectors.selectExercise(state);
    const isSubExercise = subExercise.size > 0;
    const feedbackMessages = isSubExercise
      ? subExercise.get('feedbackMessages')
      : exercise.get('user').get('feedbackMessages');
    const exerciseId = isSubExercise
      ? subExercise.get('id')
      : exercise.get('id');
    const courseId = selectors.selectCourse(state).get('id');
    const isPreview = isTeachPreview(history.location.pathname);
    const lastSubmission = selectors.selectLastCode(state);
    const scrubbedLastSubmission =
      lastSubmission != null || isPreview
        ? scrubAPITokens({ courseId, submission: lastSubmission })
        : lastSubmission;
    const feedbackMessage = feedbackMessages.find(
      (message: any) => message.isActive,
    );
    const ratingType = feedbackMessage.messageType === 'HINT' ? 'hint' : 'sct';
    const settings = {
      exerciseId,
      userFeedback: action.userFeedback,
      lastSubmission: scrubbedLastSubmission,
      rating: action.rating,
      ratingType,
      extraInfo: feedbackMessage.body,
    };
    return getCampusApiClient()
      .execute(ApiClient.endpoint.postFeedback(settings), action)
      .catch((error: any) => Rx.Observable.of(error));
  });

export const epicSubmitIssue = (action$: any, store: any, history: any) =>
  action$.ofType(actions.EPIC_SUBMIT_ISSUE).concatMap((action: any) => {
    const state = store.getState();
    const exercise = selectors.selectExercise(state);
    const lastSubmission = selectors.selectLastCode(state);
    const courseId = selectors.selectCourse(state).get('id');
    const isPreview = isTeachPreview(history.location.pathname);
    const scrubbedLastSubmission =
      lastSubmission != null || isPreview
        ? scrubAPITokens({ courseId, submission: lastSubmission })
        : lastSubmission;
    const settings = {
      id: exercise.get('id'),
      category: action.category,
      extra_info: action.userFeedback,
      last_submission: scrubbedLastSubmission,
    };
    return getCampusApiClient()
      .execute(ApiClient.endpoint.postFeedbackIssue(settings), action)
      .catch((error: any) => Rx.Observable.of(error));
  });

export const epicShowPayWallModal = (action$: any, store: any) =>
  action$.ofType(actions.SHOW_PAYWALL_MODAL).concatMap(() => {
    const state = store.getState();
    const chapterId = selectors.selectChapterId(state);
    const courseId = selectors.selectCourse(state).get('id');
    const locale = selectors.selectPathLanguage(state);
    return getMainAppApiClient()
      .execute(
        ApiClient.endpoint.window({
          type: 'paywall',
          chapter_id: chapterId,
          course_id: courseId,
          locale,
        }),
        actions.epicGotWindowResponse(),
      )
      .concatMapOnSucceedRequest((r: any) => {
        return Rx.Observable.of({ ...r, blockInteraction: true });
      })
      .catch(() => Rx.Observable.empty());
  });
