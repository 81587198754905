import request from 'superagent';

import config from '../../../config';
import prometheusClient from '../../../helpers/prometheusClient';
import { USER_STATUS } from '../../selectors/user';

const client = prometheusClient();
const { appName } = config;

const logUserDetailsRequestFailure = (
  statusObj: request.Response | request.ResponseError,
  timeoutObj: request.ResponseError & { timeout?: boolean }, // Superagent doesn't export a TimeoutError type
  attempt: number,
): void => {
  const status_code = statusObj?.status ?? 'none';
  const timeout = timeoutObj?.timeout != null;
  const formatted_status_code = [
    'none',
    200,
    400,
    403,
    404,
    500,
    502,
    503,
    504,
  ].includes(status_code)
    ? status_code.toString()
    : 'untracked';

  client.increment(
    'count_failed_auth_call',
    {
      appName,
      status_code: formatted_status_code,
      timeout: timeout.toString(),
      attempt: attempt.toString(),
    },
    1,
  );
};

const USER_DETAILS_REQUEST_TIMEOUT_IN_MS = 15000;

export default () => {
  return request
    .get(`${config.urls.datacamp}/api/users/signed_in`)
    .withCredentials()
    .set('Accept', 'application/json')
    .set('X-Requested-With', 'XMLHttpRequest')
    .retry(1, (err, res) => {
      logUserDetailsRequestFailure(res, err, 1);
    })
    .timeout({
      response: USER_DETAILS_REQUEST_TIMEOUT_IN_MS,
    })
    .then(({ body }: any) => body)
    .then((userDetails: any) => ({
      settings: {
        ...userDetails,
        has_active_subscription: userDetails.active_products.some(
          (productSlug: string) => productSlug.startsWith('learn.'),
        ),
        multiplexerUrl: config.urls.multiplexer,
      },
      status: USER_STATUS.LOGIN,
    }))
    .catch((error) => {
      const random = Math.random().toString(36).slice(2);
      const isUnauthorizedUser = 'status' in error && error.status === 401;
      if (isUnauthorizedUser) {
        return {
          settings: {
            multiplexerUrl: config.urls.multiplexer,
            email: `visitor_${random}`,
            authentication_token: `visitor_token_${random}`,
          },
          status: USER_STATUS.UNAUTHORIZED,
        };
      }
      logUserDetailsRequestFailure(error, error, 2);
      return {
        settings: {
          multiplexerUrl: config.urls.multiplexer,
          email: `visitor_${random}`,
          authentication_token: `visitor_token_${random}`,
        },
        status: USER_STATUS.ERROR,
      };
    });
};
