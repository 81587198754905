import { Exercise } from '../../helpers/fetchExternalExerciseIds';
import unreachable from '../../interfaces/unreachable';
import { ImagesState } from '../reducers/images';

const shouldUseDefaultImage = (
  exercise_image: string | undefined,
): exercise_image is undefined => exercise_image == null;

export const getImageAndRuntimeConfig = ({
  course,
  exercise,
  images,
}: {
  course: Record<string, unknown>;
  exercise: Exercise;
  images: ImagesState;
}): { image: string | undefined; runtimeConfig: string | undefined } => {
  if (images == null) {
    throw new Error(
      'Images have not been fetched yet, should be done before starting session',
    );
  }

  // TODO improve typing
  const courseRuntimeConfig = course.runtime_config as string;

  switch (images.type) {
    case 'notFound':
      return {
        image: undefined,
        runtimeConfig: courseRuntimeConfig,
      };
    case 'singleImage':
      return {
        image: images.imageTag,
        runtimeConfig: courseRuntimeConfig,
      };
    case 'multiImage': {
      if (shouldUseDefaultImage(exercise.exercise_image)) {
        return {
          image: images.imageNameToImageTag.default,
          runtimeConfig: courseRuntimeConfig,
        };
      }
      const exerciseImageName = exercise.exercise_image;
      const imageTag = images.imageNameToImageTag[exerciseImageName];
      return {
        image: imageTag,
        runtimeConfig: exercise.runtime_config,
      };
    }
    default:
      return unreachable(images);
  }
};
