import { fromJS } from 'immutable';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';

import * as actions from '../actions';
import { USER_STATUS } from '../selectors/user';

export const initialState = fromJS({
  status: USER_STATUS.NOT_INITIATE,
  settings: {
    aiFlags: {
      aiSolutionExplanationEnabled: false,
      aiErrorExplanationEnabled: false,
    },
  },
});

export default (state = initialState, action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'entities' does not exist on type '{}'.
      return isNil(action.entities.user)
        ? state
        : state
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'entities' does not exist on type '{}'.
            .set('status', action.entities.user.status)
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'entities' does not exist on type '{}'.
            .mergeDeepIn(['settings'], fromJS(action.entities.user.settings));
    case actions.USER_ENABLED_FOR_AI_FEATURES_FETCHED:
      return state.setIn(
        ['settings', 'aiFlags'],
        // @ts-expect-error Property 'aiFlags' does not exist on type '{}'.
        action.aiFlags,
      );
    default:
      return state;
  }
};
