import { timeout } from 'rxjs/operators';

import ApiClient, {
  ActiveCourseImagesResponse,
} from '../../../helpers/ApiClient';
import { getImbClient } from '../../../helpers/getClients';
import { PreFetchedDataState } from '../../reducers/preFetchedData';
import { REQUEST_STATUS } from '../../selectors';

export const getCourseImagesAsPromise = async ({
  courseAsPromise,
  preFetchedData,
}: {
  courseAsPromise: Promise<any>;
  preFetchedData: PreFetchedDataState;
}): Promise<ActiveCourseImagesResponse> => {
  const preFetchedDataRequest = preFetchedData.get('courseImages');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return preFetchedDataRequest.get('data') as ActiveCourseImagesResponse;
    case REQUEST_STATUS.FAILED:
      return Promise.reject(preFetchedDataRequest.get('data'));
    default: {
      const course = await courseAsPromise;
      const courseImages = await getImbClient()
        .execute(
          ApiClient.imbEndpoints.getCourseImages({ courseId: course.id }),
        )
        .pipe(timeout(5000))
        .toPromise()
        .then(({ body }: { body: unknown }) => body);

      return ActiveCourseImagesResponse.parse(courseImages);
    }
  }
};
