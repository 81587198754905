import { t } from 'i18next';
import { fromJS, List as list } from 'immutable';
import { v4 } from 'uuid';

import { replaceHexToString } from '../../../helpers/unicode';
import { MessageType } from '../../selectors';

export { MessageType };

export const update = (state: any, action: any, overrideMessage = {}) => {
  const lastRunCode = state.get('lastRunCode');
  const message = {
    key: v4(),
    isActive: true,
    messageType: MessageType.INCORRECT,
    title: t('IncorrectFeedbackMessage.title'),
    ratedFeedback: false,
    body: replaceHexToString(action.output.payload.message),
    lastRunCode,
    ...overrideMessage,
  };
  return state.update('feedbackMessages', (feedbackMessages = list()) =>
    feedbackMessages.map((msg) => ({ ...msg, isActive: false })).push(message),
  );
};

export const updateWithAssistantFeedbackMessage = (
  state: any,
  error: string,
) => {
  const lastRunCode = state.get('lastRunCode');
  const message = {
    key: v4(),
    error,
    isActive: true,
    messageType: MessageType.ASSISTANT,
    title: t('AssistantFeedbackMessage.title'),
    ratedFeedback: false,
    body: t('AssistantFeedbackMessage.body'),
    lastRunCode,
  };
  return state.update('feedbackMessages', (feedbackMessages = list()) =>
    feedbackMessages
      .filter((msg) => msg.messageType !== MessageType.ASSISTANT)
      .map((msg) => ({
        ...msg,
        isActive: false,
      }))
      .push(message),
  );
};

export const updateSolutionFeedback = (state: any, solution: any) => {
  const action = {
    output: {
      payload: {
        message: `<pre><code>${solution}</code></pre>`,
      },
    },
  };
  const overrideMessage = {
    title: t('SolutionFeedbackMessage.title'),
    messageType: MessageType.SOLUTION,
  };
  return update(state, action, overrideMessage);
};

export const activate = (state: any, action: any) =>
  state.update('feedbackMessages', (messages: any) =>
    messages.map((message: any, index: any) => ({
      ...message,
      isActive: action.tabIndex === index,
    })),
  );

export const complete = (state: any, action: any) => {
  const previousStateCompleted = state.getIn(['completed', 'completed']);
  return state.set(
    'completed',
    fromJS({
      message: replaceHexToString(action.message),
      show: action.show,
      completed: action.completed,
      isFirstTime: !previousStateCompleted && action.completed,
    }),
  );
};

export const markActiveFeedbackAsRated = (state: any) =>
  state.update('feedbackMessages', (feedbackMessages: any) =>
    feedbackMessages.map((feedbackMessage: any) =>
      feedbackMessage.isActive
        ? { ...feedbackMessage, ratedFeedback: true }
        : feedbackMessage,
    ),
  );
