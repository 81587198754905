import { fromJS, List as list, Map as hashMap } from 'immutable';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import rxRequest from 'universal-rx-request';

import {
  isTabOrBulletConsoleEx,
  resetProgress as resetTabOrBulletConsole,
} from '../../../helpers/ComposedConsoleExercise';
import * as actions from '../../actions';
import { Action, emptyAction } from '../../actions';
import { MODAL_TYPE } from '../../selectors';

import initialSubExerciseState from './initialSubExerciseState';
import subExercise from './subExercise';
import userReducer from './user';

const { STATUS: REQUEST_STATUS, getStatus } = rxRequest;

const gotToUserReducer = (exercise: any, progress: any, action: any) => {
  if (!exercise) {
    return null;
  }
  return userReducer(exercise.get('user'), exercise, progress, action);
};

const saveCodeState = (state: any, action: any) => {
  const exIndex = state
    .get('all')
    .findIndex((ex: any) => ex.get('id') === action.id);
  if (exIndex < 0) {
    return state;
  }
  return state.updateIn(
    ['all', exIndex, 'user', action.category],
    (tabs = hashMap()) => {
      if (!tabs.get(action.tabKey)) {
        return tabs;
      }
      return tabs.mergeDeep({
        [action.tabKey]: {
          props: { code: action.code, extra: action.extra },
        },
      });
    },
  );
};

const getCurrentExercise = (state: any) =>
  state.getIn(['all', state.get('current')], null);

// The chapter is completed if no uncompleted exercise is found
const isChapterCompleted = (state: any) =>
  state.get('all').findIndex(
    (exercise: any, index: any) =>
      // Find not completed exercise
      !(
        state.getIn(['progress', index, 'completed'], false) ||
        exercise.getIn(['user', 'completed', 'completed'], false)
      ),
  ) === -1;

export default (state = hashMap(), action: Action = emptyAction) => {
  const currentProgressExercise =
    state.getIn(['progress', state.get('current')]) || hashMap();

  const user = gotToUserReducer(
    getCurrentExercise(state),
    currentProgressExercise,
    action,
  );
  if (user) state = state.setIn(['all', state.get('current'), 'user'], user); // eslint-disable-line

  // initialSubExerciseState contains a guard to not perform any update it if it was already done
  if (!isNil(state.get('current'))) {
    // eslint-disable-next-line
    state = state.setIn(
      ['all', state.get('current')],
      initialSubExerciseState(
        getCurrentExercise(state),
        currentProgressExercise.get('subexercises'),
      ),
    );
  }

  if (
    getCurrentExercise(state) &&
    getCurrentExercise(state).get('subexercises')
  ) {
    // eslint-disable-next-line
    state = state.updateIn(['all', state.get('current')], (exercise) =>
      subExercise(exercise, action),
    );
  }

  switch (action.type) {
    case actions.BOOT_SUCCEEDED: {
      const current = action.exerciseNumber - 1;
      const { chapterProgress: progress, exercises } = action.entities;
      return state
        .set('current', current)
        .update('all', (allState = fromJS(exercises)) =>
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          allState.zipWith(
            (currentExercise: any, newExercise: any) =>
              currentExercise.mergeDeep(newExercise),
            fromJS(exercises),
          ),
        )
        .setIn(
          ['all', action.exerciseNumber - 1, 'user'],
          userReducer(
            null,
            fromJS(exercises[current]),
            fromJS(progress ? progress[current] : {}),
          ),
        )
        .update((currentState) =>
          progress
            ? currentState.set('progress', fromJS(progress))
            : currentState,
        )
        .set('canRateChapter', false)
        .update((currentState) =>
          currentState.set(
            'isChapterCompleted',
            isChapterCompleted(currentState),
          ),
        );
    }
    case actions.UPDATE_CURRENT_EXERCISE: {
      const newExercise = state.getIn(['all', action.index], null);
      const newProgressExercise =
        state.getIn(['progress', action.index]) || hashMap();
      const userUpdated = gotToUserReducer(
        newExercise,
        newProgressExercise,
        action,
      );
      let newState = state
        .set('current', action.index)
        .setIn(['all', action.index, 'user'], userUpdated)
        .set('canRateChapter', false);
      if (isTabOrBulletConsoleEx(newExercise && newExercise.get('type'))) {
        newState = newState.updateIn(['all', action.index], (ex) =>
          resetTabOrBulletConsole(ex),
        );
      }
      return newState;
    }
    case actions.UPDATE_EXERCISES_PROGRESS:
      if (action.data.status === 'failed' || isEmpty(action.data)) {
        return state;
      }
      return state.set('progress', fromJS(action.data));
    case actions.SAVE_CODE:
      return saveCodeState(state, action);
    case getStatus(actions.EPIC_UPDATE_EXERCISES, REQUEST_STATUS.SUCCESS):
      return state.update((s) =>
        s
          .set(
            'all',
            fromJS(
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{}'.
              action.data.body.map((ex: any) => ({
                ...ex,
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'injection' does not exist on type '{}'.
                ...action.injection,
                randomNumber: Math.random(),
              })),
            ),
          )
          .set('current', null)
          .set('canRateChapter', false)
          // eslint-disable-next-line sonarjs/no-identical-functions
          .update((currentState) =>
            currentState.set(
              'isChapterCompleted',
              isChapterCompleted(currentState),
            ),
          ),
      );
    case getStatus(actions.EPIC_UPDATE_EXERCISES, REQUEST_STATUS.ERROR):
      console.error('error to retrieve exercises, should give an explanation!'); // eslint-disable-line no-console
      return state.update((s) => s.set('all', list()).set('current', null));
    case actions.SUBMIT_EXTERNAL_EXERCISE:
    case actions.COMPLETE_EXERCISE:
      return !state.get('isChapterCompleted') && isChapterCompleted(state)
        ? state.set('isChapterCompleted', true).set('canRateChapter', true)
        : state;
    case actions.SHOW_MODAL:
      return action.modal.code === MODAL_TYPE.CHAPTER_RATING.code
        ? state.set('canRateChapter', false)
        : state;
    default:
      return state;
  }
};
