import { State } from '../../interfaces/State';
import { DatawarehouseSessionState } from '../reducers/datawarehouseSession';

export const selectDatawarehouseSession = (
  state: State,
): DatawarehouseSessionState => state.get('datawarehouseSession');

export const selectDatawarehouseSessionIsReady = (state: State): boolean => {
  const { status } = selectDatawarehouseSession(state);
  return status === 'success' || status === 'no-session';
};

export const selectDatawarehouseSessionIsBusy = (state: State): boolean =>
  selectDatawarehouseSession(state).status === 'loading';

export const selectDatawarehouseSessionIsBroken = (state: State): boolean =>
  selectDatawarehouseSession(state).status === 'error';
