// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
import { getTabTitle } from '@datacamp/dc-languages-config';
import { t } from 'i18next';
import { fromJS, List as list } from 'immutable';
import attempt from 'lodash/attempt';
import endsWith from 'lodash/endsWith';
import isError from 'lodash/isError';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import startsWith from 'lodash/startsWith';
import uniqueId from 'lodash/uniqueId';

import { MessageType, updateSolutionFeedback } from './feedbackMessages';

export const updateSolutionTab = (
  state: any,
  exercise: any,
  language: any,
  key = 'solution',
) => {
  const isLegacyExercise = !startsWith(exercise.get('sample_code'), '[');
  if (isLegacyExercise) {
    const solutionTabTitle = getTabTitle(key, language, exercise.get('type'));
    const solutionTabKey = `solution/${solutionTabTitle}`;
    return state.update('editorTabs', (editorTabs: any) =>
      editorTabs
        .map((tab: any) => tab.setIn(['props', 'active'], false))
        .set(
          solutionTabKey,
          fromJS({
            title: solutionTabTitle.replace('ReadOnly', ''), // Some solution files have "readonly" appended to the name
            isSolution: true,
            props: {
              active: true,
              extraClass: 'animation--flash solution',
              extra: {},
            },
          }),
        ),
    );
  }
  return state;
};

export const showHint = (state: any, exercise: any, action: any) =>
  state.update((s: any) =>
    s
      .update('feedbackMessages', (messages = list()) =>
        messages
          .map((message) => ({ ...message, isActive: false }))
          .push({
            isActive: true,
            title: t('HintFeedbackMessage.title'),
            ratedFeedback: false,
            messageType: MessageType.HINT,
          }),
      )
      .set('isHintShown', true)
      .set('sidePanelIsClosed', false)
      .update(
        'currentXp',
        (currentXp = exercise.get('xp')) => currentXp - (action.xp || 0),
      ),
  );

export const showSolution = (
  state: any,
  exercise: any,
  solutionKeyForMarkdown: any,
  action: any,
) => {
  switch (exercise.get('type')) {
    case 'MarkdownExercise': {
      let solutions = attempt(() => JSON.parse(exercise.get('solution')));
      if (isError(solutions)) {
        return state;
      }
      solutions = mapKeys(
        mapValues(solutions, (value, key) => ({
          title: key,
          isSolution: true,
          props: {
            code: value,
            resetCode: value,
            uniqueId: uniqueId('code-editor-'),
            active: endsWith(key, '.Rmd'),
          },
          extraClass: 'animation--flash solution solution-markdown-title',
        })),
        (_, key) => solutionKeyForMarkdown + key,
      );
      return state
        .update('inputMarkdownTabs', (inputs: any) =>
          inputs
            .map((tab: any) => tab.set('active', false))
            .mergeDeep(solutions),
        )
        .set('isSolutionShown', true)
        .update('currentXp', (currentXp: any) => currentXp - (action.xp || 0));
    }
    case 'ConsoleExercise':
      return updateSolutionFeedback(state, exercise.get('solution', ''))
        .set('isSolutionShown', true)
        .update('currentXp', (currentXp: any) => currentXp - (action.xp || 0));
    default:
      return updateSolutionTab(state, exercise, action.language)
        .set('isSolutionShown', true)
        .update('currentXp', (currentXp: any) => currentXp - (action.xp || 0));
  }
};
