import * as actions from '../actions';
import { BOOT_SUCCEEDED } from '../actions';
import { LearningRecap } from '../middlewares/boot/getLearningRecapAsPromise';

export type LearningRecapState = LearningRecap | null;

export const learningRecap = (
  state = null,
  action: actions.Action,
): LearningRecapState => {
  switch (action.type) {
    case BOOT_SUCCEEDED:
      return action.learningRecap;
    default:
      return state;
  }
};
