import { Map as hashMap } from 'immutable';
import { createSelector } from 'reselect';

import getNeighborsUrl, { getSplittedPathName } from '../../helpers/navigation';

import { selectChapters, selectCourse } from './course';
import { selectCurrentChapterNbExercises } from './exercises';
import { selectLearningMode } from './learningMode';

export const selectCanonical = (state: any) =>
  state.getIn(['location', 'canonical'], null);
export const selectPathLanguage = (state: any) =>
  state.getIn(['location', 'language']);
export const selectLocation = (state: any) =>
  state.getIn(['location', 'current']);
export const selectPathName = (state: any) =>
  state.getIn(['location', 'current', 'pathname']);
export const selectExNumber = (state: any) =>
  Number(state.getIn(['location', 'current', 'query', 'ex'], 1));
export const selectQuery = (state: any) =>
  state.getIn(['location', 'current', 'query']) || hashMap();
export const selectPreviousQuery = (state: any) =>
  state.getIn(['location', 'before', 'query']) || hashMap();
export const selectQueryNoFocus = (state: any) =>
  selectQuery(state).get('noFocus');

/* Should be unit tested! */
export const selectSplittedPath = createSelector(
  selectLocation,
  selectExNumber,
  selectLearningMode,
  (location, exerciseNumber, learningMode) => {
    const { chapterRef, courseRef } = getSplittedPathName(
      location.get('pathname', ''),
    );
    return { courseRef, chapterRef, exerciseNumber, learningMode };
  },
);

export const selectNeighborsExercises = createSelector(
  selectCourse,
  selectChapters,
  selectCurrentChapterNbExercises,
  selectSplittedPath,
  selectQuery,
  selectPathLanguage,
  getNeighborsUrl,
);
