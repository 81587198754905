import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';

import * as actions from '../actions';
import { Action } from '../actions';

export type OnboardingMilestonesState = {
  isActive: boolean;
  isStarted: boolean;
  step: number;
};

const initialState: OnboardingMilestonesState = {
  isStarted: false,
  isActive: true,
  step: 0,
};

const videoExerciseType = videoExerciseConstants.exercises.VIDEO_EXERCISE;

export default (
  state = initialState,
  action: Action,
): OnboardingMilestonesState => {
  switch (action.type) {
    case actions.ADVANCE_ONBOARDING_MILESTONES:
    case actions.NEXT_INTERNAL_EXERCISE:
    case actions.NEXT_EXTERNAL_EXERCISE:
      if (state.isStarted) {
        return { ...state, isActive: false };
      }
      return state;
    case actions.SUBMIT_EXTERNAL_EXERCISE: {
      const shouldAdvanceAndShowNexStep =
        state.isStarted &&
        action.correct &&
        action.exerciseType !== videoExerciseType;
      if (shouldAdvanceAndShowNexStep) {
        return { ...state, isActive: true, step: state.step + 1 };
      }
      return state;
    }
    case actions.COMPLETE_EXERCISE: {
      const shouldAdvanceAndShowNexStep = state.isStarted && action.completed;
      if (shouldAdvanceAndShowNexStep) {
        return { ...state, isActive: true, step: state.step + 1 };
      }
      return state;
    }
    case actions.ONBOARDING_MILESTONES_STARTED:
      return { ...state, isStarted: true };
    default:
      return state;
  }
};
